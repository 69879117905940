const CorrectNumber = (num, max) => {
    var input = num;
    var absInput = Math.abs(num);
    if (input < 0) {
        const outcome = max - ( max * ((absInput/max) - Math.floor(absInput/max)) )
        if (outcome === max) {
            return 0.0001
        } else {
            return outcome
        }
    } else if (input >= max ) {
        return ((input/max) - Math.floor(input/max)) * max
    } else {
        return input
    }
}

export default CorrectNumber;