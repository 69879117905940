import React, { useCallback, useContext, useEffect, useState } from 'react';
import Interact from '../Spinteract/Interact';
import SpinContext from '../../contexts/SpinContext';

const ViewSpin = (props) => {
	const { spinId } = props;
	const [loading, setLoading] = useState(true);
	const [loadedFullSpin, setLoadedFullSpin] = useState(false);
	const [imageUriArray, setImageUriArray] = useState([]);
	const [lowImageUriArray, setLowImageUriArray] = useState(null);
	const [showZoom, setShowZoom] = useState(false);
	const [zoomOutAction, setZoomOutAction] = useState(false);
	const [zoomInAction, setZoomInAction] = useState(false);

	const triggerLoaded = async (id) => {
		await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/Spin/Viewed?spinId=${id}`, {
			method: 'GET',
		});
	};

	useEffect(() => {
		if (spinId > 0) {
			const delay = setTimeout(() => {
				triggerLoaded(spinId);
				clearTimeout(delay);
			}, 2000);
		}
	}, [spinId]);

	const changeImageUriArray = useCallback(
		(value) => {
			setImageUriArray(value);
		},
		[setImageUriArray]
	);
	const changeLowImageUriArray = useCallback(
		(value) => {
			setLowImageUriArray(value);
		},
		[setLowImageUriArray]
	);

	const changeShowZoom = useCallback(
		(value) => {
			setShowZoom(value);
		},
		[setShowZoom]
	);

	const changeLoading = useCallback(
		(value) => {
			setLoading(value);
		},
		[setLoading]
	);

	const [promptIsVisible, setPromptIsVisible] = useState(true);

	const spinStateObject = {
		imageUriArray: imageUriArray,
		changeImageUriArray,
		lowImageUriArray: lowImageUriArray,
		changeLowImageUriArray,
		showZoom: showZoom,
		changeShowZoom,
		loading: loading,
		changeLoading,
		promptIsVisible,
		setPromptIsVisible,
		zoomOutAction,
		setZoomOutAction,
		zoomInAction,
		setZoomInAction,
		loadedFullSpin,
		setLoadedFullSpin,
	};

	return (
		<SpinContext.Provider value={spinStateObject}>
			<Interact
				id={props.spinId}
				isFullPage={props.isFullPage}
				changeTitle={props.changeTitle}
				mode={props.mode ? props.mode : 'embed'}
				displayWidth={props.displayWidth}
				displayHeight={props.displayHeight}
			/>
		</SpinContext.Provider>
	);
};

export default ViewSpin;
