import React from 'react';
import { Dialog, DialogContent, DialogActions, Box, Slide } from '@mui/material';
import { isNullOrUndefined } from '../../functions/misc';
import { Link } from 'react-router-dom';
import ViewSpin from '../Spinteract/ViewSpin';
import { CustomButton, CustomIconButton } from '../buttons/CustomButton';
import { Edit, X } from 'tabler-icons-react';
import ShareDialogButton from './ShareDialogButton';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
  });

function PreviewDialog(props) {
	const { onClose, open, id, mySpinsPage, customContent } = props;
	const handleClosePreviewDialog = () => {
		onClose();
	};
	return (
		<Dialog
			fullWidth={true}
			maxWidth={false}
			onClose={handleClosePreviewDialog}
			open={open}
			TransitionComponent={Transition}
			PaperProps={{
				sx: { width: '100%', height: '90%', margin: '0px', position: 'absolute', bottom: 0, borderTopLeftRadius: '20px', borderTopRightRadius: '20px' },
			}}
		>
			<DialogActions>
				
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'wrap',
						gap: '10px',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					{/* {mySpinsPage && (
						<> */}
						{customContent}
							
						{/* </>
					)} */}

					<CustomIconButton icon={<X size={16} />} onClick={handleClosePreviewDialog} variant="hover" />
				</div>
			</DialogActions>
			<DialogContent
				sx={{
					padding: 0,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						m: 'auto',
						width: '100%',
						height: '100%',
					}}
				>
					{/* <ViewSpin spinId={id} mode={mySpinsPage ? 'myspins' : null} /> */}

					<iframe
						src={`/share/${id}${mySpinsPage ? '?mode=myspins' : ''}`}
						style={{
							width: '100%',
							height: '100%',
							border: 'none',
						}}
					></iframe>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default PreviewDialog;
