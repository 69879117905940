import { createSlice, current } from '@reduxjs/toolkit';

export const appInfoSlice = createSlice({
	name: 'appInfo',
	initialState: {
		backendUrl: process.env.REACT_APP_BACKEND_BASE_URL,
	},
	reducers: {
		setBackendUrl: (state, action) => {
			state.backendUrl = action.payload;
		},
	},
});

export const { setBackendUrl } = appInfoSlice.actions;

export const backendUrlValue = (state) => state.appInfo.backendUrl;

export default appInfoSlice.reducer;
