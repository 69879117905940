
const isNullOrUndefined = (item) => {
    return typeof item === 'undefined' || item === null
}

const getAuthToken = async (context) => {
    try {
        return await context.getAccessTokenSilently({
				audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        // audience: `https://prod-backend.prosp.in/`,
          });
    }
    catch {
        return null
    }
    
}

const delaySomething = (milisec) => {
    return new Promise((resolve) => {
      const delay = setTimeout(() => {
        resolve();
        clearTimeout(delay)
      }, milisec);
    });
  };

  const isOptionChecked = (target) => {
    var checked = null;
			try {
				checked = !isNullOrUndefined(target) && !isNullOrUndefined(target.checked) ? target.checked : !target.querySelector('input').checked;
			} catch {}
      return checked
  }

  const checkAuthOnLoad = (loadingAuthState, isAuthState, historyUtil) => {
		if (loadingAuthState === false && isAuthState === false) {
			historyUtil.push("/")
		}
	}

  const getAspectRatioFromFile = (file) => {
		return new Promise(resolve => {
			const img = new Image();
			const objUrl = URL.createObjectURL(file);
			img.onload = () => {
				URL.revokeObjectURL(objUrl)
				resolve({width: img.naturalWidth, height: img.naturalHeight, aspect: img.naturalWidth / img.naturalHeight})
			}
			img.src = objUrl
		})
	}

  const getQuality = (aspect, window) => {
	const thresholds = { high: 1200, medium: 800 };
  var dimension = aspect > 1 ? window.outerWidth : window.outerHeight;
    var quality = 'low';
    if (dimension > thresholds.high) {
      quality = 'high';
    } else if (dimension > thresholds.medium) {
      quality = 'medium';
    } else {
      quality = 'low';
    }
    return quality
  }
  

export {
    isNullOrUndefined,
    getAuthToken,
    delaySomething,
    isOptionChecked,
    checkAuthOnLoad,
    getAspectRatioFromFile,
    getQuality
  }