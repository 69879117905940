import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isNullOrUndefined } from '../../../functions/misc';
import { selectedImageValue, spinDataGetter } from '../../../store/interactionSlice';

const AnimationCanvas = (props) => {
	const { onLoadCB, downloadedImages, mode, lowResImages, refreshKey, zoom, backendUrl } = props;
	const _images = useSelector(spinDataGetter('images'));
	const _selectedImage = useSelector(selectedImageValue);
	const imgCanvas = useRef(null);

	const [currentImageUrl, setCurrentImageUrl] = useState('');
	const animateWithImageElement = useCallback(() => {
		if (isNullOrUndefined(downloadedImages)) {
			return;
		}
		if (isNullOrUndefined(_images)) {
			return;
		}
		const currRootImage = _images[_selectedImage];
		var currentImage = downloadedImages.find((x) => x.id == currRootImage.id);
		if (isNullOrUndefined(currentImage)) {
			// load from low res
			if (!isNullOrUndefined(lowResImages)) {
				currentImage = lowResImages.find((x) => x.id == currRootImage.id);
			}
		}
		try {
			if (!isNullOrUndefined(currentImage.displayUrl)) {
				setCurrentImageUrl(currentImage.displayUrl);
			}
		} catch {
			setCurrentImageUrl('');
		}
		if (isNullOrUndefined(currentImage)) {
			// TODO: maybe -- if still undefined load from blob url associated with image
			return;
		}
		if (isNullOrUndefined(imgCanvas)) {
			return;
		}
		if (isNullOrUndefined(imgCanvas.current)) {
			return;
		}
		if (isNullOrUndefined(currentImage)) {
			return;
		}
		if (isNullOrUndefined(currentImage.imgElement)) {
			return;
		}
		onLoadCB();
		imgCanvas.current.width = currentImage.imgElement.width;
		imgCanvas.current.height = currentImage.imgElement.height;
		imgCanvas.current.getContext('2d').drawImage(currentImage.imgElement, 0, 0, currentImage.imgElement.width, currentImage.imgElement.height);
	}, [imgCanvas, _selectedImage, _images, downloadedImages, mode, lowResImages]);

	useEffect(() => {
		animateWithImageElement();
	}, [_selectedImage, downloadedImages, refreshKey, _images]);

	return (
		<>
			{!isNullOrUndefined(_images) && _images.length > 0 && (
				<>
					{zoom && (
						<img
							style={{
								objectFit: 'contain',
								width: '100%',
								height: '100%',
								position: 'absolute',
							}}
							src={
								currentImageUrl.includes('octospin-original-1')
									? currentImageUrl
									: `${backendUrl}/api/Image/SpinImage/${_images[_selectedImage].spinId}/${_images[_selectedImage].id}/zoom?${_images[_selectedImage].cropQuery}`
							}
						></img>
					)}
					<canvas
						ref={imgCanvas}
						className={'animationCanvas'}
						style={{
							objectFit: 'contain',
							width: '100%',
							height: '100%',
						}}
					></canvas>
				</>
			)}
		</>
	);
};

export default AnimationCanvas;
export const MemoizedAnimationCanvas = React.memo(AnimationCanvas);
