import React, { useCallback, useEffect, useState } from 'react';
import { HandFinger, ArrowNarrowLeft, ArrowNarrowRight, ArrowsHorizontal } from 'tabler-icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { animationOffValue, setInitialLoadIndex } from '../../store/interactionSlice';
import CustomIcon360 from '../icons/CustomIcon360';
const AnimatedPrompt = (props) => {
	const [load, setLoad] = useState(1);
	const { animatedPromptDropshadow, animatedPromptColor } = props;

	return (
		<div
			key={load}
			style={{
				position: 'relative',
					filter: animatedPromptDropshadow ? 'drop-shadow(rgba(0, 0, 0, .7) 1px 2px 2.8px)' : null,
			}}
		>
			<div>
			<CustomIcon360 color={animatedPromptColor} />

			</div>

			<div
				className="animatedHand"
				// style={{
				// 	filter: animatedPromptDropshadow ? 'drop-shadow(0.125rem 0.25rem 0.25rem rgb(0 0 0 / 0.3))' : null,
				// }}
			>
				<HandFinger size={'2.2rem'} strokeWidth={1.5} color={animatedPromptColor} />
			</div>
		</div>
	);
};

export default AnimatedPrompt;
