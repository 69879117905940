import { Badge } from '@mantine/core';
function CustomBadge(props) {
	const { backgroundColor, color, mantineColor, text, variant, size, caseSensative } = props;
	return (
			<Badge
			color={mantineColor}
				variant={variant}
				styles={{
					root: {
						backgroundColor: backgroundColor ? backgroundColor : null,
						'& .mantine-Badge-inner': {
							textTransform: caseSensative ? 'none' : null
							
						}
					},
					light: {
						color: color ? color : null,
					},
					
				}}
				size={size}
			>
				{text}
			</Badge>
	);
}

export default CustomBadge;
