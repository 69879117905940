import React from "react";
import {CircularProgress} from '@mui/material';

const LoadingSpinnerCenter = (props) => {
  const {color} = props
  return (
    <div
    className="loadingSpinnerWrapper"
    >
        <CircularProgress
        style={{color: color ? color : '#1976d2'}}
        />
    </div>
  );
};

export default LoadingSpinnerCenter;