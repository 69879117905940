import { Button, ActionIcon } from '@mantine/core';
import { Link, useHistory } from 'react-router-dom';
import { ExternalLink } from 'tabler-icons-react';
import CustomBadge from './CustomBadge';

const CustomButton = (props) => {
	const {
		useLink,
		text,
		loading,
		loaderSize,
		color,
		hoverColor,
		to,
		target,
		rel,
		externalLink,
		icon,
		onClick,
		disabled,
		variant,
		type,
		className,
		size,
		badge,
		badgeColor,
		badgeBGColor,
		textColor,
		id,
		title
	} = props;
	return (
		<div
			style={{
				position: 'relative',
			}}
			title={title}
		>
			{badge && (
				<div
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						zIndex: '100',
						transform: 'translate(10%, -58%)'
					}}
				>
					<CustomBadge size="xs" color={badgeColor} backgroundColor={badgeBGColor} text={badge} caseSensative={true} />
				</div>
			)}
			<Button
				id={id}
			
				className={className}
				target={target}
				type={type}
				rel={rel}
				size={size ? size : 'md'}
				variant={variant ? variant : 'filled'}
				compact
				styles={{
					root: {
						backgroundColor: variant && variant !== 'filled' ? null : color ? color : 'var(--basic-button-color)',
						width: 'fit-content',
						borderRadius: '100px',
						fontWeight: 'normal',
						height: size == 'lg' ? '40px' : '32px',
						padding: size == 'lg' ? '4px 16px' : '1px 12px',
						opacity: loading ? '.8' : null,
						color: textColor ? textColor : null,
						':hover': { backgroundColor: variant && variant !== 'filled' ? null : hoverColor ? hoverColor : 'var(--basic-button-color-hover)' },
						'&::before': {
							backgroundColor: 'rgba(220, 220, 220, 0.25)',
							borderRadius: '100px',
						},
					},
					outline: {
						color: color,
						borderColor: color,
						':hover': {
							backgroundColor: hoverColor ? hoverColor : 'var(--icon-button-hover)',
						},
					},
					subtle: {
						color: color ? color : 'var(--basic-text-color)',
						':hover': {
							backgroundColor: 'var(--icon-button-hover)',
						},
					},
				}}
				loading={loading}
				disabled={disabled ? disabled : null}
				leftIcon={icon}
				rightIcon={externalLink ? <ExternalLink size={'.9rem'} /> : null}
				onClick={(e) => {
					if (to) {
						// e.preventDefault();
						// history.push(to);
						return;
					}
					onClick(e);
				}}
				loaderProps={{
					height: loaderSize,
					width: loaderSize,
					fontSize: loaderSize,
				}}
				component={useLink ? Link : to ? 'a' : null}
				to={useLink ? to : null}
				href={useLink ? null : to}
			>
				{text}
			</Button>
		</div>
	);
};

const CustomIconButton = (props) => {
	const history = useHistory();
	const { id, size, color, hoverColor, to, target, icon, onClick, disabled, variant, noInteract, className, title } = props;
	return (
		<ActionIcon
			aria-label={title}
			title={title}
			id={id}
			className={className}
			size= {size ? size : "lg"}
			disabled={disabled ? disabled : null}
			styles={{
				root: {
					pointerEvents: noInteract ? 'none' : null,
					borderRadius: '500px',
				},
				
				filled: {
					backgroundColor: color ? color : 'var(--basic-button-color)',
					':hover': { backgroundColor: hoverColor ? hoverColor : 'var(--basic-button-color-hover)' },
				},
				outline: {
					borderColor: color,
					color: color,
				':disabled': {
					opacity: '0.5',
					backgroundColor: 'transparent !important',

				},

				':hover': {
					backgroundColor: hoverColor ? hoverColor : 'rgba(155,155,155,0.2)',
				},
				},
				hover: {
						border: 'none !important',
						color: color ? color : 'var(--basic-text-color)',
					':disabled': {
						opacity: '0.5',
						backgroundColor: 'transparent !important',

					},

					':hover': {
						backgroundColor: hoverColor ? hoverColor : 'rgba(155,155,155,0.2)',
					},
				},
			}}
			color={variant && 'var(--basic-text-color)'}
			variant={variant ? variant : 'filled'}
			component={to ? 'a' : null}
			target={target}
			href={to}
			onClick={(e) => {
				if (to) {
					e.preventDefault();
					history.push(to);
					return;
				}
				onClick(e);
			}}
		>
			{icon}
		</ActionIcon>
	);
};

export { CustomButton, CustomIconButton };
