import React, { useState } from 'react';
import { Switch } from '@mantine/core';
import Information from '../../dialogs/Information';

function CustomSwitch(props) {
	var { customWidth, label, customColor, hideSwitchObject, customTextColor, checked, handleChange, disabled, infoValue, onLabel, offLabel, leftRightPadding, fontSize } = props;
	return (
		<div
			style={{
				//width: 'calc(100% - 32px)',
				width: customWidth ? customWidth : leftRightPadding ? `calc(100% - ${leftRightPadding * 2}px)` : 'calc(100% - 32px)',
				display: 'flex',
				flexDirection: 'row',
				//gap: '4px',
				alignContent: 'center',
				justifyContent: 'space-between',
				padding: leftRightPadding ? `8px ${leftRightPadding}px 8px ${leftRightPadding}px` : '8px 16px 8px 16px',
				fontSize: fontSize ? fontSize : '0.96rem',
				cursor: disabled ? 'not-allowed' : 'auto',
			}}
			onClick={disabled ? null : handleChange}
			data-checked={checked}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignContent: 'center',
					marginTop: 'auto',
					marginBottom: 'auto',
					//passes clicks to parent
					pointerEvents: 'none',
				}}
			>
				<span
					style={{
						userSelect: 'none',
						margin: 'auto',
					}}
				>
					{label}
				</span>
				{infoValue && (
					<div
						onClick={(e) => {
							e.stopPropagation();
						}}
						style={{
							pointerEvents: 'all',
						}}
					>
						<Information value={infoValue} />
					</div>
				)}
			</div>
			<Switch
				style={{
					marginLeft: '8px',
				}}
				onLabel={onLabel ? onLabel : null}
				offLabel={offLabel ? offLabel : null}
				size="sm"
				disabled={disabled ? disabled : false}
				checked={checked}
				// onChange={() => {}}
				readOnly
				name={label ? label : ''}
				styles={{
					root: { opacity: disabled ? '0.5 !important' : hideSwitchObject ? '0 !important' : null },
					input: {
						borderColor: !checked
							? customTextColor
								? `${customTextColor} !important`
								: 'var(--switch-off-border-color) !important'
							: customColor && checked && !disabled
							? `${customColor} !important`
							: '#1971c2 !important',
						backgroundColor: !checked
							? 'transparent !important'
							: customColor && checked && !disabled
							? `${customColor} !important`
							: '#1971c2 !important',
						'::before': {
							borderColor: !checked ? (customTextColor ? `${customTextColor} !important` : 'var(--switch-off-border-color) !important') : null,
						},
					},
				}}
			/>
		</div>
	);
}

export default CustomSwitch;
export const MemoizedCustomSwitch = React.memo(CustomSwitch);
