import React, { useContext, useState, useCallback, useEffect } from 'react';
import PreviewDialog from '../components/dialogs/PreviewDialog';
import { useParams } from 'react-router';
import AppContext from '../contexts/AppContext';
import { Link, useHistory } from 'react-router-dom';
import { backendUrlValue } from '../store/appInfoSlice';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const UserProfile = () => {
	const history = useHistory();
	let { username } = useParams();

	const _AppContext = useContext(AppContext);
	const BACKEND_URL = useSelector(backendUrlValue);

	const [userSpins, setUserSpins] = useState([]);
	const [noneFound, setNoneFound] = useState(false);
	const [currentSpinForPreviewDialog, setCurrentSpinForPreviewDialog] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const handleClickOpenPreviewDialog = () => {
		setOpenPreviewDialog(true);
	};
	const handleClosePreviewDialog = () => {
		setOpenPreviewDialog(false);
		const delay = setTimeout(() => {
			setCurrentSpinForPreviewDialog(0);
			clearTimeout(delay)
		}, 200)
	};
	const handlePreviewClick = useCallback(
		(item) => {
			setCurrentSpinForPreviewDialog(item);
			handleClickOpenPreviewDialog();
		},
		[setCurrentSpinForPreviewDialog, handleClickOpenPreviewDialog]
	);

	const userContactToProperHref = (contact) => {
		if (contact.includes(`@`)) {
			const mailtoPrefix = 'mailto:';
			if (contact.substring(0, mailtoPrefix.length) !== mailtoPrefix) {
				return `mailto:${contact}`;
			} else {
				return contact;
			}
		} else {
			const httpPrefix = 'http://';
			const httpsPrefix = 'https://';

			if (contact.substring(0, httpPrefix.length) == httpPrefix) {
				return `https://${contact.substring(httpPrefix.length)}`;
			} else if (contact.substring(0, httpsPrefix.length) == httpsPrefix) {
				return contact;
			} else {
				return `https://${contact}`;
			}
		}
	};

	useEffect(() => {
		if (BACKEND_URL == null) {
			return;
		}
		fetch(`${BACKEND_URL}/api/Inspire/InspireSpinsByUser?username=${username}`)
			.then((res) => {
				if (res.status == 200) {
					return res.json();
				} else {
					return [];
				}
			})
			.then((data) => {
				setUserSpins(data);
				if (data.length == 0) {
					setNoneFound(true);
				}
			})
			.catch((error) => history.push(`/error?message=${error}`));
	}, [BACKEND_URL]);

	return (
		<div
			style={{
				margin: '20px',
			}}
		>
			{!(username == null) && username.length > 0 && userSpins.length > 0 ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}
				>
					<span
						style={{
							fontSize: '1.6rem',
							fontWeight: 600,
						}}
					>
						@{username}
					</span>

					{!_AppContext.isLoading && _AppContext.isAuth ? (
						<>
							<span
								style={{
									fontSize: '.9rem',
									fontWeight: 400,
								}}
							>
								<a
									style={{
										textDecoration: 'none',
										color: 'var(--sub-text)',
									}}
									href={userContactToProperHref(userSpins[0].userContact)}
									target="_blank"
									rel="noreferrer"
								>
									{userSpins[0].userContact}
								</a>
							</span>
							<span
								style={{
									width: '100%',
									maxWidth: '600px',
									textAlign: 'center',
								}}
							>
								{userSpins[0].description}
							</span>
						</>
					) : (
						<span
							style={{
								textDecoration: 'none',
								color: 'var(--sub-text)',
								fontSize: '.9rem',
								fontWeight: 400,
							}}
						>
							Log in to view contact info
						</span>
					)}

					<div className="allSpinsWrapper">
						{userSpins.map((spin) => (
							// <Link tabIndex={0} to={`/v/${spin.id}`} className="inspireSpinItemWrapper">
							<a
								tabIndex={0}
								onClick={() => {
									handlePreviewClick(spin.spinId);
								}}
								onKeyDown={(e) => {
									if (e.key == ' ' || e.key.toLowerCase() == 'enter') {
										handlePreviewClick(spin.spinId);
									}
								}}
								className="inspireSpinItemWrapper"
							>

								<img className="inspireImage" loading="lazy" src={spin.posterUrl} />
							</a>
						))}
					</div>
					{currentSpinForPreviewDialog !== 0 && (
						<PreviewDialog 
						id={currentSpinForPreviewDialog} open={openPreviewDialog} onClose={handleClosePreviewDialog} />
					)}

				</div>
			) : (
				<>
					{noneFound ? (
						<div style={{ textAlign: 'center' }}>Sorry, no spins found for this username.</div>
					) : (
						<>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									margin: '40px',
								}}
							>
								<CircularProgress size={'2rem'} style={{ color: '#1976d2' }} />
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default UserProfile;
