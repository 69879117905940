import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import ViewSpin from '../components/Spinteract/ViewSpin';

const Embed = (props) => {
	var { id, mode } = useParams();
	// const adjustUrl = (spinId) => {
	// 	//const stateObject = { view: `share_${spinId}` };
	// 	window.history.replaceState(null, '', `/share/${spinId}`);
	// };
	// useEffect(() => {
	// }, [id]);
	return <ViewSpin isFullPage={true} changeTitle={true} spinId={id} mode={mode} />;
};

export default Embed;
