import React from 'react';
import AnimationCircle from '../components/loading/AnimationCircle';



const LogoAnimated = (props) => {
	const { changeForDarkMode } = props;

	const { dimensions } = props;
	return (
		<div className="logoOverarch">
			<svg
				className={changeForDarkMode ? 'logoWrapper changeForDarkMode logoStill' : 'logoWrapper logoStill'}
				style={{
					height: dimensions.height,
					width: dimensions.width,
					fontSize: '100px',
				}}
				viewBox="0 0 100 80"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle className="body" cx="50" cy="25" r="24" fill="9d4edd" />
				<circle className="armOne" cx="50" cy="67" r="12" fill="#bb73f5" />
				<circle className="armTwo" cx="23" cy="62" r="11" fill="#ab5fe8" />
				<circle className="armThree" cx="10" cy="48" r="8" fill="#9d4edd" />
				<circle className="armFour" cx="25" cy="33" r="6" fill="#8a39cc" />
				<circle className="armFive" cx="50" cy="28" r="4" fill="#7929ba" />
				<circle className="armSix" cx="75" cy="33" r="6" fill="#8a39cc" />
				<circle className="armSeven" cx="90" cy="48" r="8" fill="#9d4edd" />
				<circle className="armEight" cx="77" cy="62" r="11" fill="#ab5fe8" />
				<circle className="body" cx="50" cy="25" r="24" fill="#9d4edd" />
			</svg>
			<svg
				className={changeForDarkMode ? 'logoWrapper changeForDarkMode logoMoving' : 'logoWrapper logoMoving'}
				style={{
					height: dimensions.height,
					width: dimensions.width,
					fontSize: '100px',
				}}
				viewBox="0 0 100 80"
				xmlns="http://www.w3.org/2000/svg"
			>
				<AnimationCircle duration="1s" r_start="12" r_end="11" cx_start="50" cx_end="23" cy_start="67" cy_end="62" fill_start="#bb73f5" fill_end="#ab5fe8" />
				<AnimationCircle duration="1s" r_start="11" r_end="8" cx_start="23" cx_end="10" cy_start="62" cy_end="48" fill_start="#ab5fe8" fill_end="#9d4edd" />
				<AnimationCircle duration="1s" r_start="8" r_end="6" cx_start="10" cx_end="25" cy_start="48" cy_end="33" fill_start="#9d4edd" fill_end="#8a39cc" />
				<AnimationCircle duration="1s" r_start="6" r_end="4" cx_start="25" cx_end="50" cy_start="33" cy_end="28" fill_start="#8a39cc" fill_end="#7929ba" />
				<AnimationCircle duration="1s" r_start="4" r_end="6" cx_start="50" cx_end="75" cy_start="28" cy_end="33" fill_start="#7929ba" fill_end="#8a39cc" />
				<AnimationCircle duration="1s" r_start="6" r_end="8" cx_start="75" cx_end="90" cy_start="33" cy_end="48" fill_start="#8a39cc" fill_end="#9d4edd" />
				<AnimationCircle duration="1s" r_start="8" r_end="11" cx_start="90" cx_end="77" cy_start="48" cy_end="62" fill_start="#9d4edd" fill_end="#ab5fe8" />
				<AnimationCircle duration="1s" r_start="11" r_end="12" cx_start="77" cx_end="50" cy_start="62" cy_end="67" fill_start="#ab5fe8" fill_end="#bb73f5" />

				<circle className="body" cx="50" cy="25" r="24" fill="#9d4edd" />

			</svg>
		</div>
	);
};

export default LogoAnimated;
