import React, { useContext, useState, useCallback, useRef, useEffect } from 'react';
import SpinContext from '../../contexts/SpinContext';
import { Settings, CompareArrows, Check, CloseOutlined } from '@mui/icons-material';
import { Tooltip, NativeSelect, ListItemIcon, Menu, MenuItem, IconButton } from '@mui/material';
import { isNullOrUndefined } from '../../functions/misc';
import CustomSlider from '../Settings/settingComponents/CustomSlider';
import CustomSwitch from '../Settings/settingComponents/CustomSwitch';
import { CustomIconButton } from '../buttons/CustomButton';
import { X } from 'tabler-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { spinDataGetter, updateSpinData } from '../../store/interactionSlice';

const SettingsMenu = (props) => {
	const _SpinContext = useContext(SpinContext);
	const wrapperMenuRef = useRef(null);
	const reverseDirectionItem = useRef(null);
	const [anchorElement, setAnchorElement] = useState(null);
	const open = Boolean(anchorElement);
	const dispatch = useDispatch();
	const _id = useSelector(spinDataGetter('id'));
	const _showPlaybackControls = useSelector(spinDataGetter('showPlaybackControls'));
	const _dragDirectionNegative = useSelector(spinDataGetter('dragDirectionNegative'));
	const _showZoomButton = useSelector(spinDataGetter('showZoomButton'));
	const _dragFactorRangeValue = useSelector(spinDataGetter('dragFactorRangeValue'));
	const _settingsMenuBackgroundColor = useSelector(spinDataGetter('settingsMenuBackgroundColor'));
	const _basicButtonBackgroundColor = useSelector(spinDataGetter('basicButtonBackgroundColor'));
	const _settingsMenuTextColor = useSelector(spinDataGetter('settingsMenuTextColor'));
	const _settingsMenuButtonColor = useSelector(spinDataGetter('settingsMenuButtonColor'));
	const _playSpeedRangeValue = useSelector(spinDataGetter('playSpeedRangeValue'));
	const handleClick = useCallback(
		(e) => {
			if (open) {
				setAnchorElement(null);
			}
			setAnchorElement(e.target);
		},
		[open]
	);
	const handleClose = () => {
		setAnchorElement(null);
	};

	const dragFactorMarks = [
		{
			value: 0,
			label: 'Slow',
		},
		{
			value: 9,
			label: 'Fast',
		},
	];
	const [basePrompt, setBasePrompt] = useState('');

	const handleShowArrowsChange = useCallback(() => {
		dispatch(updateSpinData({ k: 'showPlaybackControls', v: !_showPlaybackControls }));
	}, [basePrompt, _showPlaybackControls]);

	const handleShowZoomButtomChange = useCallback(() => {
		dispatch(updateSpinData({ k: 'showZoomButton', v: !_showZoomButton }));
	}, [_showZoomButton, basePrompt]);

	const [showReverseCheck, setShowReverseCheck] = useState(false);

	const [initialDragRangeValue, setInitialDragRangeValue] = useState(null);

	useEffect(() => {
		if (isNullOrUndefined(_dragFactorRangeValue)) {
			return;
		}
		setInitialDragRangeValue(_dragFactorRangeValue);
	}, [_dragFactorRangeValue]);

	return (
		<div>
			<Tooltip title="Change Settings" placement="top">
				<CustomIconButton
					id="settings-menu-button"
					aria-controls="settings-menu-button"
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					variant="hover"
					icon={<Settings />}
					color={_basicButtonBackgroundColor}
					onClick={handleClick}
				/>
			</Tooltip>

			<Menu
				tabIndex="-1"
				className="settingsMenu"
				id="settings-menu-overarch"
				anchorEl={anchorElement}
				open={open}
				onClose={handleClose}
				sx={{
					zIndex: 1400,
				}}
				MenuListProps={{
					sx: {
						paddingTop: 0
					}
				}}
				PaperProps={{
					sx: {
						backgroundColor: _settingsMenuBackgroundColor,
						// padding: '0px 12px 12px 12px',
						borderRadius: '16px',
						backdropFilter: 'blur(8px)',
						maxHeight: 'calc(100% - 32px)'
					},
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<div 
				style={{
					position: 'sticky',
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
					top: 0,
					right: 0,
					backgroundColor: _settingsMenuBackgroundColor,
					padding: '8px',
					zIndex: 10

				}}
				>
					<CustomIconButton
					icon={<CloseOutlined/>}
					id="settings-menu-close-button"
					aria-controls="settings-menu-close-button"
					variant="hover"
					color={_settingsMenuButtonColor}
					onClick={handleClose}
					size="sm"
					/>
				</div>
				{props.mode === 'edit' && (
					<Tooltip disableInteractive={true} title="Settings unavailable during edit" placement="top">
						<div
							style={{
								position: 'absolute',
								top: 0,
								height: '100%',
								width: '100%',
							}}
						></div>
					</Tooltip>
				)}
				

				<div
					ref={wrapperMenuRef}
					tabIndex="-1"
					style={{
						color: _settingsMenuTextColor,
						padding: '0px 12px 12px 12px'
					}}
					onFocus={(e) => {
						if (e.target == wrapperMenuRef.current) {
							reverseDirectionItem.current.focus();
						}
					}}
				>
					{props.mode === 'edit' && <span style={{ fontStyle: 'italic', opacity: 0.7 }}>Settings unavailable during edit</span>}
					{/* <> */}

					<MenuItem
						ref={reverseDirectionItem}
						tabIndex={0}
						onClick={() => {
							dispatch(updateSpinData({ k: 'dragDirectionNegative', v: !_dragDirectionNegative }));
							setShowReverseCheck(true);
							setTimeout(() => {
								setShowReverseCheck(false);
							}, 2000);
						}}
						className={props.mode === 'edit' ? 'disabledOnEdit customSettingsFocus' : 'customSettingsFocus'}
						style={{
							padding: '12px 8px',
							color: showReverseCheck === true ? '#009f00' : 'inherit',
						}}
					>
						<ListItemIcon>
							{showReverseCheck === false ? (
								<CompareArrows
									style={{
										color: _settingsMenuButtonColor,
									}}
								/>
							) : (
								<Check style={{ color: '#009f00' }} />
							)}
						</ListItemIcon>
						Reverse Direction
					</MenuItem>
					{!isNullOrUndefined(_dragFactorRangeValue) && (
						<div
							tabIndex={1}
							onKeyDown={(e) => {
								if (e.key === 'ArrowLeft') {
									// handleChange('dragFactorRangeValue', _dragFactorRangeValue >= 1 ? _dragFactorRangeValue - 1 : 0);
									dispatch(updateSpinData({ k: 'dragFactorRangeValue', v: _dragFactorRangeValue >= 1 ? _dragFactorRangeValue - 1 : 0 }));
									dispatch(updateSpinData({ k: 'playSpeedRangeValue', v: _playSpeedRangeValue >= 1 ? _playSpeedRangeValue - 1 : 0 }));
								}
								if (e.key === 'ArrowRight') {
									// handleChange('dragFactorRangeValue', _dragFactorRangeValue <= 8 ? _dragFactorRangeValue + 1 : 9);
									dispatch(updateSpinData({ k: 'dragFactorRangeValue', v: _dragFactorRangeValue <= 8 ? _dragFactorRangeValue + 1 : 9 }));
									dispatch(updateSpinData({ k: 'playSpeedRangeValue', v: _playSpeedRangeValue <= 8 ? _playSpeedRangeValue + 1 : 9 }));
								}
							}}
							style={{
								padding: '8px 8px',
							}}
							className="sliderSettingsWrapper"
						>
							<CustomSlider
								aria-label="Spin Speed Input Range"
								value={_dragFactorRangeValue}
								handleChange={(value) => {
									dispatch(updateSpinData({ k: 'dragFactorRangeValue', v: value }));
									dispatch(updateSpinData({ k: 'playSpeedRangeValue', v: value }));
								}}
								tabIndex={1}
								startingValueNotch={initialDragRangeValue}
								step={1}
								marks={dragFactorMarks}
								min={0}
								max={9}
								inputClassName="settingsSlider"
								color={_settingsMenuButtonColor}
								textColor={_settingsMenuTextColor}
								noInteract={props.mode === 'edit' ? true : false}
							/>
						</div>
					)}

					<MenuItem
						tabIndex={0}
						onClick={handleShowArrowsChange}
						disableRipple
						className={props.mode === 'edit' ? 'disabledOnEdit customSettingsFocus' : 'customSettingsFocus'}
						style={{
							padding: '0px',
						}}
					>
						<CustomSwitch
							customTextColor={_settingsMenuTextColor}
							leftRightPadding={8}
							fontSize="1rem"
							label="Show Controls"
							infoValue={null}
							customColor={_settingsMenuButtonColor}
							checked={_showPlaybackControls}
							// handleChange={handleShowArrowsChange}
						/>
					</MenuItem>
					<MenuItem
						tabIndex={0}
						onClick={handleShowZoomButtomChange}
						disableRipple
						className={props.mode === 'edit' ? 'disabledOnEdit customSettingsFocus' : 'customSettingsFocus'}
						style={{
							padding: '0px',
						}}
					>
						<CustomSwitch
							customTextColor={_settingsMenuTextColor}
							leftRightPadding={8}
							fontSize="1rem"
							label="Show Zoom Button"
							infoValue={null}
							customColor={_settingsMenuButtonColor}
							checked={_showZoomButton}
							// handleChange={handleShowArrowsChange}
						/>
					</MenuItem>
				</div>
				{/* </> */}
			</Menu>
		</div>
	);
};

export default SettingsMenu;
