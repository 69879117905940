import { configureStore } from '@reduxjs/toolkit';
import interactionReducer from './interactionSlice';
import appInfoReducer from './appInfoSlice';
import cropperReducer from './cropperSlice';

export default configureStore({
  reducer: {
    interaction: interactionReducer,
    appInfo: appInfoReducer,
    // spinData: spinDataReducer,
    cropper: cropperReducer,
  },
});