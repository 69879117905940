import { createSlice, current } from '@reduxjs/toolkit';
import store from './store';

export const cropperSlice = createSlice({
	name: 'cropper',
	initialState: {
        spinCropStateOnEditPageLoad: [],
        changedImages: [],
        // changedImages: [],
	},
	reducers: {
		
        setSpinCropStateOnEditPageLoad: (state, action) => {
            state.spinCropStateOnEditPageLoad = action.payload;
        },
        handleChangedImages: (state, action) => {
            const imageCropData = action.payload
            try {
                if (state.changedImages.findIndex((x) => x.id == imageCropData.id) > -1) {
					state.changedImages.map((item) => {
						if (item.id == imageCropData.id) {
							return imageCropData;
						} else {
							return item;
						}
					});
				} else {
                    state.changedImages.push(imageCropData);
					
				}
            } catch (err) {
                console.error('octo_error_2347692', err.message)
            }
        }
	
	},
});

export const {
	changeImagePositionToInner,
    setSpinCropStateOnEditPageLoad,
    handleChangedImages
} = cropperSlice.actions;

export const spinCropStateOnEditPageLoadValue = (state) => state.cropper.spinCropStateOnEditPageLoad;


export default cropperSlice.reducer;
