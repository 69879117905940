import React, { useEffect } from 'react';
import { isNullOrUndefined } from '../../../functions/misc';
import { Slider } from '@mantine/core';

function CustomSlider(props) {
	var { startingValueNotch, noInteract, min, max, value, marks, handleChange, textColor, color } = props;

	return (
		<Slider
			marks={startingValueNotch ? [...marks, { value: startingValueNotch, label: '' }] : marks}
			min={min}
			max={max}
			onChange={handleChange}
			value={value}
			showLabelOnHover={false}
			label={null}
			sx={{
				pointerEvents: noInteract ? 'none' : null,
				paddingBottom: '16px',
			}}
			styles={{
				markLabel: {
					color: !isNullOrUndefined(textColor) && textColor.length > 0 ? textColor : 'var(--basic-text-color)',
				},
				bar: {
					backgroundColor: !isNullOrUndefined(color) && color.length > 0 ? color : '#1971c2',
				},
				thumb: {
					borderColor: !isNullOrUndefined(color) && color.length > 0 ? color : '#1971c2',
				},
				mark: {
					borderColor: 'transparent !important',
					border: 'none !important',
					transform: 'scale(.5) translateX(-100%)',
					backgroundColor: '#fff !important',
				},
			}}
		/>
	);
}

export default CustomSlider;
