import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { Auth0Provider } from "@auth0/auth0-react";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Embed from './views/Embed';
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
	<Auth0Provider
		// domain="octospin.us.auth0.com"
		domain={process.env.REACT_APP_AUTH0_DOMAIN}
		// clientId="vIWoH7xQKUeJauqcfj6igEth8TQJMcnB"
		// clientId="oUUr332WKXjkjWJJakReYwZ1o4m2m662"
		clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
		useRefreshTokens="true"
		cacheLocation="localstorage"
		// redirectUri={window.location.origin}
		// audience="https://spinning.auth0.com/api/v2/"
		// audience="https://dev-backend.prosp.in/"
		// audience="https://prod-backend.prosp.in/"
		audience={process.env.REACT_APP_AUTH0_AUDIENCE}
		// redirectUri={window.location.origin + '/login'}
		redirectUri={window.location.origin + '/success'}
		// screen_hint="signup"
	>
		<React.StrictMode>
			<Router>
				<App />
				{/* <span>test</span> */}
			</Router>
		</React.StrictMode>
	</Auth0Provider>,

	document.getElementById('root')
);

reportWebVitals();
