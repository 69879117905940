import { delaySomething } from "../misc";

const createImg = (url) => {
	return new Promise((resolve, reject) => {
		try {
			if (url.length == 0) {
				resolve(null)
			}
			const img = document.createElement('img');
			img.crossOrigin = 'anonymous';
			img.onload = () => {
				img.width = img.naturalWidth;
				img.height = img.naturalHeight;
				resolve(img);
			};
			img.src = url;
		} catch {
			reject();
		}
	});
};

const createIBM = (url) => {
	return new Promise(async (resolve, reject) => {
		
		try {
			if (url.length == 0) {
				resolve(null)
			}
			const response = await fetch(url);
			const blob = await response.blob();
			const bitmap = await createImageBitmap(blob);
			resolve(bitmap);
		} catch (err) {
            console.error('caught', typeof url, "-" + url + "-", err)
			reject();
		}
	});
};

const calcInitImages = (images, first) => {
	images = images.map((x) => x.id);
	var prevNegative = first - 5 < 0 ? images.slice(first - 5) : [];
	var prev6Over0 = images.slice(first - 5 > 0 ? first - 5 : 0, first);
	var sixAfterFirst = images.slice(first, first + 5);
	return prevNegative.concat(prev6Over0).concat(sixAfterFirst);
};

const getElement = (image, resolution) => {
	return new Promise(async (resolve, reject) => {
	
		var response;
        var tries = 0
        var gotten = false
        while (tries < 20 && !gotten) {
            try {
                if ('createImageBitmap' in window) {
                    response = await createIBM(resolution == 'low' ? image.urlThumbnail : image.displayUrl);
                } else {
                    response = await createImg(resolution == 'low' ? image.urlThumbnail : image.displayUrl);
                }
                gotten = true
                resolve(response);
            } catch {
                tries += 1
            }
            await delaySomething(500 * tries)

        }
        if (tries >= 20 && !gotten) {
            resolve(null)
        }
		
	});
};

const downloadImages = (images, first, initIdsCB) => {
	return new Promise(async (resolve) => {
		images = images.sort((a, b) => a.order - b.order);
		var imagesToLoadInitially = calcInitImages(images, first);
		initIdsCB(imagesToLoadInitially);
		images = images.filter((x) => imagesToLoadInitially.includes(x.id));
        var chunkedImages = [];
        var processed = []
	    const chunkSize = 5;
        for (let i = 0; i < images.length; i += chunkSize) {
            chunkedImages.push(images.slice(i, i + chunkSize));
        }
        for (var i = 0; i < chunkedImages.length; i++) {
            await Promise.all(
                chunkedImages[i].map(async (image) => {
                    image.imgElement = await getElement(image, 'normal');
                    processed.push(image)
                    return
                })
            );
        }
		resolve(processed);
	});
};

const loadRemainingImages = async (alreadyDownloaded, images, pushCB, chunkSize = 5) => {
	images = images.filter((x) => !alreadyDownloaded.includes(x.id));

	var chunkedImages = [];
	// const chunkSize = 5;
	for (let i = 0; i < images.length; i += chunkSize) {
		chunkedImages.push(images.slice(i, i + chunkSize));
	}
	for (var i = 0; i < chunkedImages.length; i++) {
		var processed = await Promise.all(
			chunkedImages[i].map(async (image) => {
				image = { ...image };
				image.imgElement = await getElement(image, 'normal');
				return image;
			})
		);

		pushCB(processed);
	}
	return;
};

const downloadLowResImages = (images, first) => {
	return new Promise(async (resolve, reject) => {
		images = images.sort((a, b) => a.order - b.order);
		var skippableImages = calcInitImages(images, first);
		images = images.filter((x) => !skippableImages.includes(x.id));

        //Reminder: Do not chunk the low res

		images = await Promise.all(
			images.map(async (x) => {
				var imgElement = await getElement(x, 'low');
				return {
					id: x.id,
					order: x.order,
					imgElement: imgElement,
				};
			})
		);
		resolve(images);
	});
};

export { downloadImages, loadRemainingImages, downloadLowResImages };
