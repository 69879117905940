import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dialog, IconButton, } from '@mui/material';

import { Close, WarningAmberOutlined, InfoOutlined } from '@mui/icons-material';

const Information = (props) => {
	const { hideCloseButton = false, iconColor, value, iconSize, customTrigger, customTriggerCloseAction, ref, alert } = props;
	const [infoAnchorEl, setInfoAnchorEl] = useState(null);
	const [customTriggered, setCustomTriggered] = useState(false);
	const infoOpen = Boolean(infoAnchorEl);
	const triggerFake = useRef(null);
	const handleInfoClick = (event) => {
		setInfoAnchorEl(event.currentTarget);
	};
	const handleInfoClose = useCallback(() => {
		setInfoAnchorEl(null);
		if (customTrigger) {
			customTriggerCloseAction();
		}
	}, [customTrigger, customTriggerCloseAction]);

	useEffect(() => {
		if (!customTrigger) {
			return;
		}
		if (customTriggered) {
			return;
		}
		setCustomTriggered(true);
		setInfoAnchorEl(triggerFake.current);
	}, [customTrigger, triggerFake]);

	return (
		<>
			<IconButton
				ref={ref ? ref : triggerFake}
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={infoOpen ? 'true' : undefined}
				onClick={handleInfoClick}
				style={{
					width: 'fit-content',
					height: 'fit-content',
					color: 'rgba(100, 100, 100, .6)',
					display: customTrigger ? 'none' : null,
				}}
				size="small"
			>
				{alert ? <WarningAmberOutlined fontSize={iconSize ? iconSize : '.8rem'} /> : <InfoOutlined fontSize={iconSize ? iconSize : '.8rem'} />}
			</IconButton>

			<Dialog
				id="basic-menu"
				anchorEl={infoAnchorEl}
				open={infoOpen}
				onClose={handleInfoClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				sx={{
					zIndex: 1500,
				}}
				PaperProps={{
					sx: {
						borderRadius: '20px',
						padding: '10px',
					},
				}}
			>
				<div
					style={{
						padding: '10px 10px 40px 10px',
					}}
				>
					{!hideCloseButton && (
						<div
							style={{
								width: '100%',
								textAlign: 'right',
								marginBottom: '10px',
							}}
						>
							<IconButton
								onClick={handleInfoClose}
								size="small"
							>
								<Close fontSize="12px" />
							</IconButton>
						</div>
					)}

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '0px 8px 0px 8px',
							justifyContent: 'center',
							alignItems: 'center',
							maxWidth: '400px',
						}}
					>
						<div
							style={{
								textAlign: 'center',
							}}
						>
							{value}
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default Information;
