import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import SpinContext from '../../contexts/SpinContext';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useSelector, useDispatch } from 'react-redux';
import { selectedImageValue, mouseIsDownValue, changeMouseIsDown, changePromptVisible, spinDataGetter, pauseSpinInteract } from '../../store/interactionSlice';
import { isNullOrUndefined } from '../../functions/misc';
import { MemoizedAnimationCanvas } from './Image/AnimationCanvas';

const ZoomView = (props) => {
	const { downloadedImages, backendUrl, lowResImages, interactionCB, mode, sprites, refreshKey, compositeLoadError } = props;

	const _SpinContext = useContext(SpinContext);
	const selectedImage = useSelector(selectedImageValue);
	const mouseIsDown = useSelector(mouseIsDownValue);
	const _enableZoom = useSelector(spinDataGetter('enableZoom'));
	const _cursorStyle = useSelector(spinDataGetter('cursorStyle'));
	const _imageQualityRangeValue = useSelector(spinDataGetter('imageQualityRangeValue'));

	const dispatch = useDispatch();
	const _images = useSelector(spinDataGetter('images'));
	const [doubleListening, setDoubleListening] = useState(false);
	const [ignoreClick, setIgnoreClick] = useState(false);
	const [preventMouseEvent, setPreventMouseEvent] = useState(false);
	const [transformEnabled, setTransformEnabled] = useState(true);
	
	
	const onBackupImageLoad = useCallback(() => {
		_SpinContext.changeLoading(false);
	}, [_SpinContext]);

	const processMouseDown = useCallback(
		(e) => {
			if (_SpinContext.showZoom) {
				return;
			}

			e.stopPropagation()

			
			dispatch(changeMouseIsDown({ down: true, clientX: e.clientX }));
			dispatch(changePromptVisible(false));
			dispatch(pauseSpinInteract());
		},
		[preventMouseEvent, _SpinContext.showZoom, changeMouseIsDown]
	);

	const processTouchStart = useCallback(
		(e) => {
			if (_SpinContext.showZoom) {
				return;
			}
			if (e.touches.length == 1) {
				dispatch(changeMouseIsDown({ down: true, clientX: e.touches[0].clientX }));
				dispatch(changePromptVisible(false));
				dispatch(pauseSpinInteract());
				e.stopPropagation()
			}
			
		},
		[_SpinContext, changeMouseIsDown, dispatch]
	);

	const processPan = useCallback(
		(ref, e) => {
			if (_SpinContext.showZoom === false) {
				ref.instance.clearPanning();
				return;
			}
		},
		[_SpinContext.showZoom]
	);


	const processPinchAndPinchStart = useCallback(() => {}, [_SpinContext, changeMouseIsDown, dispatch]);

	const processPinchStop = useCallback(
		(ref, e) => {
			dispatch(changeMouseIsDown({ down: false, clientX: null }));
			if (ref.state.scale <= 1) {
				_SpinContext.changeShowZoom(false);
			} else {
				_SpinContext.changeShowZoom(true);
			}
		},
		[_SpinContext.showZoom, changeMouseIsDown, dispatch]
	);

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				objectFit: 'contain',
				userSelect: 'none',
			}}
			onMouseDown={processMouseDown}
			// onTouchMove={middleHandleTouchDrag}
			onTouchStart={processTouchStart}
			// onTouchEnd={processTouchEnd}
		>

			
			{
				<TransformWrapper

				limitToBounds={true}
					defaultScale={1}
					defaultPositionX={200}
					defaultPositionY={100}
					style={{ height: '100%', width: '100%', userSelect: 'none' }}
					// disabled={ ignoreClick === true ? true : false}
					//disabled={true}
					// disabled={true}
					// disabled={!transformEnabled }
					disabled={ignoreClick || !_enableZoom}

					onZoomStart={() => {
						dispatch(pauseSpinInteract());
						// _SpinContext.pauseSpin();
						dispatch(changePromptVisible(false));
					}}
					onZoomStop={(ref, e) => {
						if (ref.state.scale <= 1) {
							_SpinContext.changeShowZoom(false);
						} else {
							dispatch(pauseSpinInteract());
							// _SpinContext.pauseSpin();

							_SpinContext.changeShowZoom(true);
						}
					}}
					doubleClick={{
						disabled: true,
					}}
					pinch={{
						step: 2,
					}}
					wheel={{
						step: 0.02,
					}}

					// panning={{disabled: true}}
				onPanningStart={processPan}
					onPanning={processPan}
					onPanningEnd={processPan}
					onPinchingStart={processPinchAndPinchStart}
					onPinching={processPinchAndPinchStart}
					onPinchingStop={processPinchStop}
					
				>
					{({ zoomIn, zoomOut, resetTransform }) => {
						if (_SpinContext.zoomOutAction === true) {
							_SpinContext.setZoomOutAction(false);
							resetTransform(300);
						}

						if (_SpinContext.zoomInAction === true) {
							_SpinContext.setZoomInAction(false);
							setIgnoreClick(true);
							zoomIn(0.667);
							setTimeout(() => {
								_SpinContext.changeShowZoom(true);
								setIgnoreClick(false);
							}, 200);
						}

						return (
							<React.Fragment>
								<TransformComponent style={{ height: '100%', width: '100%', userSelect: 'none' }}> 
									{!isNullOrUndefined(_images) && (
										<>
										<div
											className="fadeImageWrapper"
											id="allOfTheImages"
											onMouseDown={interactionCB}
										
											onTouchStart={(e) => {
												
												interactionCB(e)
											}}
											onClick={(e) => {
												// this has to be in the transform wrapper
												if (ignoreClick === true) {
													return;
												}
												if (doubleListening === false) {
													setDoubleListening(true);
													setTimeout(() => {
														setDoubleListening(false);
													}, 400);
												} else if (doubleListening === true) {
													dispatch(changeMouseIsDown({ down: false, clientX: null }));
													if (_SpinContext.showZoom === false) {
														if (_enableZoom) {
															dispatch(pauseSpinInteract());
															setIgnoreClick(true);
															zoomIn(0.667);
															setTimeout(() => {
																_SpinContext.changeShowZoom(true);
																setIgnoreClick(false);
															}, 200);
														}
													} else if (_SpinContext.showZoom === true) {
														setIgnoreClick(true);
														setTimeout(() => {
															setIgnoreClick(false);
														}, 200);
														_SpinContext.changeShowZoom(false);
														resetTransform(300);
													}
													setDoubleListening(false);
												}
											}}
											style={{
												height: '100%',
												width: '100%',
												userSelect: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												cursor:
													_SpinContext.showZoom === false && _cursorStyle
														? _cursorStyle
														: _SpinContext.showZoom === true
														? 'move'
														: 'ew-resize',
											}}
										>
											<MemoizedAnimationCanvas
												sprites={sprites}
												onBackupImageLoad={onBackupImageLoad}
												compositeLoadError={compositeLoadError}
												images={_SpinContext.imageUriArray}
												downloadedImages={downloadedImages}
												lowResImages={lowResImages}
												onLoadCB={() => {
													_SpinContext.changeLoading(false);
												}}
												mode={mode}
												refreshKey={refreshKey}
												zoom={_SpinContext.showZoom === true && _imageQualityRangeValue > 0 && mouseIsDown !== true}
												backendUrl={backendUrl}
											/>
										</div>
										</>
											)} 
								</TransformComponent>
							</React.Fragment>
						);
					}}
				</TransformWrapper>
			}
		</div>
	);
};

export default ZoomView;
