import './App.css';
import React, { useCallback, useEffect, useState, Suspense, lazy } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import AppContext from './contexts/AppContext';
import store from './store/store';
import { Provider, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import UserProfile from './views/UserProfile';
import { isNullOrUndefined } from './functions/misc';
import TermsAgreement from './components/dialogs/TermsAgreement';
import { setScreenDimensions } from './store/interactionSlice';
import { tryGetUser } from './functions/user';

const Home = lazy(() => import('./views/Home'));
const MySpins = lazy(() => import('./views/MySpins'));
const Embed = lazy(() => import('./views/Embed'));
const Create = lazy(() => import('./views/Create'));
const Account = lazy(() => import('./views/Account'));
const Edit = lazy(() => import('./views/Edit'));
const Pricing = lazy(() => import('./views/Pricing'));
const Inspire = lazy(() => import('./views/Inspire'));
const ErrorPage = lazy(() => import('./views/ErrorPage'));
const NavBar = lazy(() => import('./components/NavBar'));
const Terms = lazy(() => import('./views/Terms'));
const Privacy = lazy(() => import('./views/Privacy'));
const Login = lazy(() => import('./views/login/Login'));
const WelcomeCheckout = lazy(() => import('./views/WelcomeCheckout'));
const Success = lazy(() => import('./views/login/Success'));
const Verified = lazy(() => import('./views/Verified'));

const InnerApp = () => {
	const location = useLocation();
	const [showNav, setShowNav] = useState(true);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setScreenDimensions({ width: window.innerWidth, height: window.innerHeight }));
	}, []);

	useEffect(() => {
		if (
			location.pathname.includes('embed') ||
			location.pathname.includes('view') ||
			location.pathname.includes('v/') ||
			location.pathname.includes('share/')
		) {
			setShowNav(false);
		} else {
			setShowNav(true);
		}
	}, [location]);

	return (
		<div className="contentContainer">
			<div
				style={{
					marginTop: showNav === true ? '40px' : '0px',
				}}
			>
				{showNav && (
					<Suspense fallback={<></>}>
						<NavBar />
					</Suspense>
				)}
			</div>
			<div
				className={showNav === true ? 'mainContentContainer' : 'embed_mainContainer'}
				style={{
					alignContent: 'center',
					justifyContent: 'center',
				}}
			>
				<Switch>
					{/* ROUTE ORDER MATTERS */}
					<Route path="/myspins">
						<Suspense fallback={<></>}>
							<MySpins />
						</Suspense>
					</Route>

					<Route path="/embed/:id/:type?">
						<Suspense fallback={<></>}>
							<Embed />
						</Suspense>
					</Route>
					<Route path="/share/:id/:type?">
						<Suspense fallback={<></>}>
							<Embed />
						</Suspense>
					</Route>
					<Route path="/v/:id/:type?">
						<Suspense fallback={<></>}>
							<Embed />
						</Suspense>
					</Route>
					<Route path="/welcome">
						<Suspense fallback={<></>}>
							<WelcomeCheckout />
						</Suspense>
					</Route>
					<Route path="/edit/:id/:type?">
						<Suspense fallback={<></>}>
							<Edit />
						</Suspense>
					</Route>
					<Route path="/create">
						<Suspense fallback={<></>}>
							<Create />
						</Suspense>
					</Route>

					<Route path="/account">
						<Suspense fallback={<></>}>
							<Account />
						</Suspense>
					</Route>
					<Route path="/pricing">
						<Suspense fallback={<></>}>
							<Pricing />
						</Suspense>
					</Route>
					<Route path="/explore">
						<Suspense fallback={<></>}>
							<Inspire />
						</Suspense>
					</Route>
					<Route path="/u/:username">
						<Suspense fallback={<></>}>
							<UserProfile />
						</Suspense>
					</Route>
					<Route path="/error">
						<Suspense fallback={<></>}>
							<ErrorPage />
						</Suspense>
					</Route>
					<Route path="/terms">
						<Suspense fallback={<></>}>
							<Terms />
						</Suspense>
					</Route>
					<Route path="/privacy">
						<Suspense fallback={<></>}>
							<Privacy />
						</Suspense>
					</Route>

					<Route path="/login">
						<Suspense fallback={<></>}>
							<Login />
						</Suspense>
					</Route>
					<Route path="/success">
						<Suspense fallback={<></>}>
							<Success />
						</Suspense>
					</Route>
					<Route path="/verified">
						<Suspense fallback={<></>}>
							<Verified />
						</Suspense>
					</Route>

					<Route path="/">
						<Suspense fallback={<></>}>
							<Home />
						</Suspense>
					</Route>
				</Switch>
				{/* </BrowserRouter> */}
			</div>
		</div>
	);
};
const MemoizedInnerApp = React.memo(InnerApp);

function App() {
	const history = useHistory();
	const location = useLocation();
	const localBaseBackendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

	const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const [userInfo_Auth, setUserInfo_Auth] = useState(
		//set defaults to null
		{
			email: null,
			email_verified: null,
			name: null,
			nickname: null,
			picture: null,
			sub: null,
			updated_at: null,
		}
	);
	const [isAuth, setIsAuth] = useState(isAuthenticated);
	const [loadingAuth, setLoadingAuth] = useState(isLoading);
	const [userInfo_Octospin, setUserInfo_Octospin] = useState(null);
	const [userId, setUserId] = useState(null);
	useEffect(() => {
		if (isLoading) {
			return;
		}
		try {
			getAccessTokenSilently({
				// audience: `https://prod-backend.prosp.in/`,
				audience: process.env.REACT_APP_AUTH0_AUDIENCE,
			});
		} catch (err) {
			console.error('43242623523 failed to get auth', err);
		}
	}, []);

	const refreshUser_Octospin = useCallback(async () => {
		if (!isAuth) {
			return;
		}
		if (userInfo_Auth) {
			if (userInfo_Auth.sub) {
				setUserId(userInfo_Auth.sub);
				var accessToken;
				try {
					accessToken = await getAccessTokenSilently({
						audience: process.env.REACT_APP_AUTH0_AUDIENCE,
						// audience: `https://prod-backend.prosp.in/`,
					});
				} catch {
					return;
				}
				//TODO: remove this
				// console.log(accessToken);
				// console.log(userInfo_Auth.sub);

				const userData = await tryGetUser(userInfo_Auth.sub, localBaseBackendUrl, accessToken).catch((error) => {
					if (error.message.toLowerCase() === 'failed to fetch') {
						history.push(`/error?message=There was an issue connecting to Octospin's infrastructure. Code 500000`);
					} else {
						history.push(`/error?message=${error}`);
					}
				});
				setUserInfo_Octospin(userData);
				if (!isNullOrUndefined(userData.darkModeEnabled)) {
					if (userData.darkModeEnabled) {
						localStorage.setItem('theme', 'dark');
					} else {
						localStorage.setItem('theme', 'light');
					}
				}
			}
		}
	}, [userInfo_Auth, isAuth]);

	// use auth user info to pull user from our db
	useEffect(async () => {
		if (location.pathname.includes('embed') || location.pathname.includes('view') || location.pathname.includes('v/')) {
			return;
		} else {
			refreshUser_Octospin();
		}
	}, [userInfo_Auth]);

	// listen for & set auth user info
	useEffect(() => {
		if (location.pathname.includes('embed') || location.pathname.includes('view') || location.pathname.includes('v/')) {
			return;
		} else {
			setUserInfo_Auth(user);
		}
	}, [user]);

	// listen for & set auth status
	useEffect(() => {
		setIsAuth(isAuthenticated);
	}, [isAuthenticated]);

	// listen for & set auth load
	useEffect(() => {
		setLoadingAuth(isLoading);
	}, [isLoading]);

	const editOctospinUser = useCallback(
		(key, value) => {
			if (key == null || value == null) {
				return;
			}
			setUserInfo_Octospin((previousState) => ({
				...previousState,
				[key]: value,
			}));
		},
		[setUserInfo_Octospin]
	);

	const appStateObject = {
		userInfo_Auth: userInfo_Auth,
		userInfo_Octospin: userInfo_Octospin,
		editOctospinUser,
		userId: userId,
		isAuth: isAuth,
		loadingAuth: loadingAuth,
		getAccessTokenSilently,
		refreshUser_Octospin,
	};

	useEffect(() => {
		document.body.dataset.theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';
	}, []);

	return (
		<AppContext.Provider value={appStateObject}>
			<Provider store={store}>
				{!isNullOrUndefined(userInfo_Octospin) &&
					!userInfo_Octospin.termsAgreementAccepted &&
					!location.pathname.includes('terms') &&
					!location.pathname.includes('privacy') && (
						<>
							<TermsAgreement />
						</>
					)}

				<MemoizedInnerApp />
			</Provider>
		</AppContext.Provider>
	);
}

export default App;
