export default function CustomIcon360(props) {
	const { color, filter } = props;
	return (
		<>
			<svg 
      style={{
        overflow: 'visible'
      }}
      width="3rem" height="2rem" font-size="2.4rem" viewBox="0 0 100 40" xmlns="http://www.w3.org/2000/svg">
				<text
					class="textThreeSixty"
					style={{
						strokeWidth: '0.04em',
						fill: color,
						fontSize: '0.95em',
						fontWeight: 'bold',
						fontFamily: 'Arial, Helvetica, sans-serif',
					}}
					x="56.5"
					y="28"
					text-anchor="middle"
				>
					360&#176;
				</text>

				<polygon
					class="spinArrow"
					style={{
						strokeWidth: '1px',
						fill: color,
						// filter: filter,
            transform: 'translateY(-0.8em)'

					}}
					points="46,76 60,84 60,68"
				/>

				<path
					style={{
						stroke: color,
						// filter: filter,
            transform: 'translateY(-0.8em)'
					}}
					class="ring"
					d="
            M 14 50
            C 4 50, 4 60, 4 60
            M 4 60 
            C 4 76, 40 76, 40 76
            M 40 76



            M 52 76
            C 96 76, 96 60, 96 60

            M 96 60

            C 96 50, 86 50, 86 50

            M 86 50
            "
					fill="transparent"
					stroke-width="5"
				/>
			</svg>
		</>
	);
}
