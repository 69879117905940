import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Dialog } from '@mui/material';
import { CustomButton } from '../buttons/CustomButton';
import AppContext from '../../contexts/AppContext';
import { getAuthToken, isNullOrUndefined } from '../../functions/misc';
import { backendUrlValue } from '../../store/appInfoSlice';
import { useSelector } from 'react-redux';

const TermsAgreement = (props) => {
	const _AppContext = useContext(AppContext);
	const BACKEND_URL = useSelector(backendUrlValue);
	const infoOpen = true;
	const [localTermsAccept, setLocalTermsAccept] = useState(false);
	const submitTermsAcceptChange = useCallback(async () => {
		if (!localTermsAccept) {
			return;
		}
		fetch(`${BACKEND_URL}/api/User/AcceptTerms?acceptTerms=${localTermsAccept}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${await getAuthToken(_AppContext)}`,
			},
		}).then(async (res) => {
			_AppContext.editOctospinUser('termsAgreementAccepted', localTermsAccept);
		});
	}, [_AppContext, BACKEND_URL, localTermsAccept]);

	return (
		<>
			<Dialog
				id="basic-menu"
				// anchorEl={infoAnchorEl}
				open={infoOpen}
				onClose={() => {}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<div
					style={{
						padding: '20px',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '0px 8px 0px 8px',
							// gap: '10px',
							justifyContent: 'center',
							alignItems: 'center',
							maxWidth: '400px',
						}}
					>
						{!isNullOrUndefined(_AppContext) && !isNullOrUndefined(_AppContext.userInfo_Octospin) && (
							<div
								style={{
									textAlign: 'center',
								}}
							>
								To use Octospin, you must agree to the <a href="/terms">terms of use</a>.
								<br />
								<br />
								<input
									id="acceptTermsCheckbox"
									onChange={(e) => {
										setLocalTermsAccept(e.target.checked);
									}}
									checked={localTermsAccept}
									type={'checkbox'}
								/>
								<label for="acceptTermsCheckbox">&nbsp;I agree to the terms of use linked above.</label>
								<br />
								<br />
								<CustomButton disabled={!localTermsAccept} onClick={submitTermsAcceptChange} text="Continue" />
							</div>
						)}
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default TermsAgreement;
