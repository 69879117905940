import React from "react";
const AnimationCircle = (props) => {
	const { duration, r_start, r_end, cx_start, cx_end, cy_start, cy_end, fill_start, fill_end, circleClass } = props;
	return (
		<>
			<circle cx={cx_start} cy={cy_start} r={r_start} fill={fill_start}>
				<animate attributeName="r" values={`${r_start};${r_end}`} dur={duration} repeatCount="indefinite" />
				<animate attributeName="cx" values={`${cx_start};${cx_end}`} dur={duration} repeatCount="indefinite" />
				<animate attributeName="cy" values={`${cy_start};${cy_end}`} dur={duration} repeatCount="indefinite" />
				<animate attributeName="fill" values={`${fill_start};${fill_end}`} dur={duration} repeatCount="indefinite" />
			</circle>
		</>
	);
};

export default AnimationCircle;