import { createSlice, current } from '@reduxjs/toolkit';
import CorrectNumber from '../functions/CorrectNumber';
import CorrectNumberBounce from '../functions/CorrectNumberBounce';
import { isNullOrUndefined } from '../functions/misc';
import store from './store';
const dragFactorArray = [528, 264, 128, 64, 32, 16, 8, 4, 2, 1];
const playSpeedFactorArray = [528, 264, 128, 64, 32, 16, 8, 4, 2, 1];
var SPIN_INTERVAL = null;

export const interactionSlice = createSlice({
	name: 'interaction',
	initialState: {
		screenDimensions: { width: 600, height: 600 },
		spinPlaying: false,
		imagePositionValueLocal: 0,
		selectedImageValueLocal: 0,
		mouseIsDownValueLocal: false,
		playInterval: null,
		promptVisible: true,
		promptRefresh: 0,
		prevClientX: 0,
		dragFactorLocalSync: 5,
		spinImagesLengthSync: 0,
		bouncePlayDirection: 1,
		initialLoadIndex: 1,
		animationOff: false,
		doNotListen: false,
		lastTimeStamps: [],
		momentum: 0,
		momentumInstance: 0,
		spinLength: 0,
		spinData: {},
		initialSpinData: {},
		autoMotionIndex: 0,
	},
	reducers: {
		setSpinData: (state, action) => {
			Object.keys(state.spinData).forEach(function (key, index) {
				state.spinData[key] = undefined;
			});
			Object.keys(action.payload).forEach(function (key, index) {
				state.spinData[key] = undefined;
				if (key == 'images') {
					state.spinLength = action.payload[key].length >= 0 ? action.payload[key].length : 0;
				}
				state.spinData[key] = JSON.parse(JSON.stringify(action.payload[key]));
				if (key == 'firstImageLoadLindex') {
					state.selectedImageValueLocal = action.payload[key] >= 0 ? action.payload[key] : 0;
				}
			});
		},
		setinitialSpinData: (state, action) => {
			Object.keys(state.initialSpinData).forEach(function (key, index) {
				state.initialSpinData[key] = undefined;
			});
			Object.keys(action.payload).forEach(function (key, index) {
				state.initialSpinData[key] = JSON.parse(JSON.stringify(action.payload[key]));
			});
		},
		updateSpinData: (state, action) => {
			if (action.payload.k == 'images') {
				state.spinLength = action.payload.v.length >= 0 ? action.payload.v.length : 0;
			}
			state.spinData[action.payload.k] = action.payload.v;
		},
		resetSpinData: (state, action) => {
			Object.keys(state.spinData).forEach(function (key, index) {
				state.spinData[key] = undefined;
			});
		},
		setScreenDimensions: (state, action) => {
			state.screenDimensions = action.payload;
		},

		changeImagePositionToInner: (state, action) => {
			state.imagePositionValueLocal = action.payload;
			state.selectedImageValueLocal = Math.trunc(action.payload);
		},

		updateSpinPlaying: (state, action) => {
			state.spinPlaying = action.payload;
		},
		updateTimeStamps: (state, action) => {
			state.lastTimeStamps.unshift(action.payload);
			state.lastTimeStamps = state.lastTimeStamps.slice(0, 10);
		},
		removeMomentum: (state) => {
			state.momentum = 0;
		},
		setMomentum: (state, action) => {
			state.momentum = action.payload;
		},
		incrementMomentumInstance: (state) => {
			state.momentumInstance += 1;
		},
		resetTimestamps: (state, action) => {
			state.lastTimeStamps = [];
		},
		setPrevClientX: (state, action) => {
			state.prevClientX = action.payload;
		},
		setMouseDownLocal: (state, action) => {
			state.mouseIsDownValueLocal = action.payload;
		},
		setDoNotListen: (state, action) => {
			state.doNotListen = action.payload;
		},
		incrementPositionByOne: (state, action) => {
			state.animationOff = action.payload;
		},
		decrementPositionByOne: (state, action) => {
			state.animationOff = action.payload;
		},
		setAnimationOff: (state, action) => {
			state.animationOff = action.payload;
		},
		setInitialLoadIndex: (state, action) => {
			state.initialLoadIndex = action.payload;
		},

		refreshPrompt: (state) => {
			state.promptRefresh += 1;
		},
		changePromptVisible: (state, action) => {
			state.promptVisible = action.payload;
		},

		syncDragFactor: (state, action) => {
			state.dragFactorLocalSync = action.payload;
		},

		syncSpinImagesLength: (state, action) => {
			state.spinImagesLengthSync = action.payload;
		},

		playIncrementPosition: (state) => {
			const startingState = { ...state };
			if (isNullOrUndefined(startingState.spinData.images)) {
				return
			}

			var dragDir = startingState.spinData.dragDirectionNegative === false ? -1 : 1;
			var playDir = startingState.spinData.flipPlayDirection === false ? 1 : -1;
			var diff = dragDir * playDir;
			let newPositionValue;
			if (startingState.spinData.bounceHalfCircle === true && startingState.imagePositionValueLocal + startingState.bouncePlayDirection * diff < 0) {
				state.bouncePlayDirection = startingState.bouncePlayDirection * -1;
				newPositionValue = CorrectNumberBounce(
					startingState.imagePositionValueLocal + startingState.bouncePlayDirection * diff * -1,
					startingState.spinData.images.length
				);
				state.imagePositionValueLocal = newPositionValue;
				state.selectedImageValueLocal = Math.trunc(newPositionValue);
				return;
			} else if (
				startingState.spinData.bounceHalfCircle === true &&
				startingState.imagePositionValueLocal + startingState.bouncePlayDirection * diff >= startingState.spinData.images.length
			) {
				state.bouncePlayDirection = startingState.bouncePlayDirection * -1;
				newPositionValue = CorrectNumberBounce(
					startingState.imagePositionValueLocal + startingState.bouncePlayDirection * diff * -1,
					startingState.spinData.images.length
				);
				state.imagePositionValueLocal = newPositionValue;
				state.selectedImageValueLocal = Math.trunc(newPositionValue);
				return;
			}
			newPositionValue = CorrectNumber(
				startingState.imagePositionValueLocal + startingState.bouncePlayDirection * diff,
				startingState.spinData.images.length
			);
			state.imagePositionValueLocal = newPositionValue;
			state.selectedImageValueLocal = Math.trunc(newPositionValue);
		},

		autoWigglePosition: (state, action) => {
			const startingState = { ...state };
			var nextAutoMotionIndex = startingState.autoMotionIndex + 1 >= action.payload.indexes.length - 1 ? 0 : startingState.autoMotionIndex + 1;
			var newPositionValue = action.payload.indexes[nextAutoMotionIndex];
			state.autoMotionIndex = nextAutoMotionIndex;
			state.imagePositionValueLocal = newPositionValue;
			state.selectedImageValueLocal = Math.trunc(newPositionValue);
		},

		incrementImagePosition: (state) => {
			state.imagePositionValueLocal = state.imagePositionValueLocal - 1;
			state.selectedImageValueLocal = Math.trunc(state.imagePositionValueLocal - 1);
		},
		stopSpinPlaying: (state) => {
			clearInterval(state.playInterval);
			state.playInterval = null;
		},
		setPlayInterval: (state, action) => {
			clearInterval(state.playInterval);
			state.playInterval = action.payload;
		},
		clearPlayInterval: (state) => {
			clearInterval(state.playInterval);
			state.playInterval = null;
		},
	},
});

export const {
	changeImagePositionToInner,
	setPrevClientX,
	updateTimeStamps,
	updateSpinPlaying,
	removeMomentum,
	setMomentum,
	incrementMomentumInstance,
	// enableMomentumValueLocal,
	resetTimestamps,
	setMouseDownLocal,
	setDoNotListen,
	setInitialLoadIndex,
	changeMouseIsDownV1,
	refreshPrompt,
	changePromptVisible,
	incrementImagePosition,
	stopSpinPlaying,
	setPlayInterval,
	clearPlayInterval,
	syncDragDirection,
	setSpinData,
	setinitialSpinData,
	updateSpinData,
	resetSpinData,
	setScreenDimensions,
	syncDragFactor,
	syncSpinImagesLength,
	playIncrementPosition,
	autoWigglePosition,
	setAnimationOff,
	spinPlaying,
} = interactionSlice.actions;

// using thunk allows you to access states of other slices
// EXAMPLE:
export const changeImagePositionTo = (amount) => (dispatch) => {
	dispatch(changeImagePositionToInner(amount));
};

export const nextPrevImageCropper = (prevNext) => (dispatch) => {
	const currentState = { ...store.getState().interaction };

	const diff = currentState.spinData.dragDirectionNegative === false ? 1 * prevNext : -1 * prevNext;

	//do not take into account bounce here for the cropper
	const newImagePosition = CorrectNumber(currentState.imagePositionValueLocal + diff, currentState.spinData.images.length);
	dispatch(changeImagePositionToInner(newImagePosition));
};

export const playSpinInteract = (prevNext) => (dispatch) => {
	const currentState = { ...store.getState().interaction };
	dispatch(updateSpinPlaying(true));
	if (SPIN_INTERVAL != null) {
		clearInterval(SPIN_INTERVAL);
		SPIN_INTERVAL = null;
	}
	SPIN_INTERVAL = setInterval(
		() => {
			dispatch(playIncrementPosition());
		},
		playSpeedFactorArray[currentState.spinData.playSpeedRangeValue] >= 0 ? playSpeedFactorArray[currentState.spinData.playSpeedRangeValue] * 5 : 1000
	);
};

export const autoMotion = () => (dispatch) => {
	const currentState = { ...store.getState().interaction };
	dispatch(updateSpinPlaying(true));
	if (SPIN_INTERVAL != null) {
		clearInterval(SPIN_INTERVAL);
		SPIN_INTERVAL = null;
	}

	var length = currentState.spinData.images.length;
	var loadIndex = currentState.spinData.firstImageLoadLindex;

	if (length <= 11) {
		SPIN_INTERVAL = setInterval(
			() => {
				dispatch(playIncrementPosition());
			},
			playSpeedFactorArray[currentState.spinData.playSpeedRangeValue] >= 0 ? playSpeedFactorArray[currentState.spinData.playSpeedRangeValue] * 5 : 1000
		);
	} else {
		var beginIndex = loadIndex - 5 < 0 ? length + (loadIndex - 5) : loadIndex - 5;

		var indexesToGo = [];

		for (var i = 0; i <= 4; i++) {
			indexesToGo.push(loadIndex + i < length ? loadIndex + i : loadIndex + i - length);
		}

		for (var i = 3; i >= 0; i--) {
			indexesToGo.push(loadIndex + i < length ? loadIndex + i : loadIndex + i - length);
		}
		for (var i = 4; i >= 0; i--) {
			indexesToGo.push(beginIndex + i < length ? beginIndex + i : beginIndex + i - length);
		}
		for (var i = 1; i <= 5; i++) {
			indexesToGo.push(beginIndex + i < length ? beginIndex + i : beginIndex + i - length);
		}

		SPIN_INTERVAL = setInterval(
			() => {
				dispatch(autoWigglePosition({ indexes: indexesToGo }));
			},
			playSpeedFactorArray[currentState.spinData.playSpeedRangeValue] >= 0 ? playSpeedFactorArray[currentState.spinData.playSpeedRangeValue] * 5 : 1000
		);
	}
};

export const pauseSpinInteract = () => (dispatch) => {
	dispatch(updateSpinPlaying(false));

	clearInterval(SPIN_INTERVAL);
	SPIN_INTERVAL = null;
};

export const moveWithMomentum = (xDiff, velocity, startingPosition) => (dispatch) => {
	const currentState = { ...store.getState().interaction };
	const momentumInstance = currentState.momentumInstance;
	// var vel = currentState.momentum;
	// dispatch(removeMomentum());
	const dir = currentState.spinData.dragDirectionNegative === false ? 1 : -1;
	const momentDir = velocity < 0 ? -1 : 1;
	var absMomentum = Math.abs(velocity);
	var correctedMomentum = absMomentum > 20 ? 20 : absMomentum;
	if (correctedMomentum == 0) {
		return;
	}
	var numImagesInSpin = currentState.spinData.images.length;
	var imagesToRotate = Math.abs(Math.floor((dir * xDiff) / dragFactorArray[currentState.spinData.dragFactorRangeValue]));
	if (imagesToRotate > 2 * numImagesInSpin) {
		imagesToRotate = 2 * numImagesInSpin;
	}
	const imgArr = Array(imagesToRotate).fill('');
	imgArr.map((x, index) => {
		var newImagePosition = currentState.spinData.bounceHalfCircle
			? CorrectNumberBounce(currentState.imagePositionValueLocal + dir * momentDir * (index + 1), currentState.spinData.images.length)
			: CorrectNumber(currentState.imagePositionValueLocal + dir * momentDir * (index + 1), currentState.spinData.images.length);
		var waitTime = Math.pow(20 * ((index + 1) / imagesToRotate), 2);
		if (waitTime <= 0 || waitTime == 1) {
			return;
		}
		waitTime = correctedMomentum * waitTime;
		var delay = setTimeout(() => {
			if (store.getState().interaction.momentumInstance != momentumInstance) {
				clearTimeout(delay);
				return;
			}
			dispatch(changeImagePositionToInner(newImagePosition));
			clearTimeout(delay);
		}, waitTime);
	});
};

export const changeMouseIsDown = (payload) => (dispatch) => {
	dispatch(removeMomentum());
	dispatch(incrementMomentumInstance());

	const currentState = { ...store.getState().interaction };
	var prevX = currentState.prevClientX;
	const addListeners = () => {
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
		// document.addEventListener('touchstart', handleTouchStart);
		document.addEventListener('touchmove', handleTouchMove);
		document.addEventListener('touchend', handleTouchEnd);
	};
	const removeListeners = () => {
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
		// document.addEventListener('touchstart', handleTouchStart);
		document.removeEventListener('touchmove', handleTouchMove);
		document.removeEventListener('touchend', handleTouchEnd);
	};
	const dragChangeImagePosition = (clientX) => {
		if (prevX == clientX) {
			return;
		}
		const dragDir = currentState.spinData.dragDirectionNegative ? -1 : 1;
		const newImagePosition = currentState.spinData.bounceHalfCircle
			? CorrectNumberBounce(
					currentState.imagePositionValueLocal + (dragDir * (clientX - prevX)) / dragFactorArray[currentState.spinData.dragFactorRangeValue],
					currentState.spinData.images.length
			  )
			: CorrectNumber(
					currentState.imagePositionValueLocal + (dragDir * (clientX - prevX)) / dragFactorArray[currentState.spinData.dragFactorRangeValue],
					currentState.spinData.images.length
			  );

		dispatch(changeImagePositionTo(newImagePosition));
		dispatch(setPrevClientX(clientX));
	};

	const handleMouseMove = (e) => {
		dragChangeImagePosition(e.clientX);
		if (currentState.spinData.enableMomentum && e.timeStamp > 0) {
			dispatch(updateTimeStamps({ time: e.timeStamp, x: e.clientX }));
		}
	};
	const initMomentum = (currTimeStamp) => {
		var stamps = store.getState().interaction.lastTimeStamps;
		dispatch(resetTimestamps());
		if (stamps.length > 0) {
			if (currTimeStamp - stamps[0].time < 75 && stamps.length > 3) {
				var velocity = (stamps[0].x - stamps[stamps.length - 1].x) / (stamps[0].time - stamps[stamps.length - 1].time);
				dispatch(moveWithMomentum(stamps[0].x - stamps[stamps.length - 1].x, velocity, store.getState().interaction.imagePositionValueLocal));
			}
		}
	};
	const handleMouseUp = (e) => {
		dragChangeImagePosition(e.clientX);
		dispatch(setMouseDownLocal(false));
		removeListeners();
		if (currentState.spinData.enableMomentum) {
			initMomentum(e.timeStamp);
		}
	};

	const handleTouchMove = (e) => {
		dragChangeImagePosition(e.touches[0].clientX);

		if (currentState.spinData.enableMomentum && e.timeStamp > 0) {
			dispatch(updateTimeStamps({ time: e.timeStamp, x: e.touches[0].clientX }));
		}
	};
	const handleTouchEnd = (e) => {
		if (!store.getState().interaction.doNotListen) {
			dragChangeImagePosition(e.changedTouches[0].clientX);
			dispatch(setMouseDownLocal(false));
		}
		if (currentState.spinData.enableMomentum) {
			initMomentum(e.timeStamp);
		}

		removeListeners();
	};
	if (payload.down & !currentState.mouseIsDownValueLocal) {
		dispatch(changePromptVisible(false));
		prevX = payload.clientX;
		if (!isNullOrUndefined(payload.clientX)) {
			dispatch(setPrevClientX(payload.clientX));
			dispatch(setDoNotListen(false));
			addListeners();
		}
	}
	if (!payload.down & currentState.mouseIsDownValueLocal) {
		dispatch(setDoNotListen(true));
		removeListeners();
	}
	if (!payload.down) {
		dispatch(setDoNotListen(true));

		removeListeners();
	}
	dispatch(setMouseDownLocal(payload.down));
};

export const imagePositionValue = (state) => state.interaction.imagePositionValueLocal;
export const selectedImageValue = (state) => state.interaction.selectedImageValueLocal;
export const mouseIsDownValue = (state) => state.interaction.mouseIsDownValueLocal;
export const promptVisibleValue = (state) => state.interaction.promptVisible;
export const promptRefreshValue = (state) => state.interaction.promptRefresh;
export const animationOffValue = (state) => state.interaction.animationOff;
export const spinPlayingValue = (state) => state.interaction.spinPlaying;
export const screenDimensionsValue = (state) => state.interaction.screenDimensions;

export const initialSpinDataValue = (state) => state.interaction.initialSpinData;
export const spinDataValue = (state) => state.interaction.spinData;
export const spinDataGetter = (key) => (state) => state.interaction.spinData[key];

export default interactionSlice.reducer;
