import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, Button, DialogTitle, TextField, InputAdornment } from '@mui/material';
import { Share, Check, Copy } from 'tabler-icons-react';
import { CustomButton } from '../buttons/CustomButton';
import { padding } from '@mui/system';

function ShareDialogButton(props) {
	const { spinId } = props;
	const [open, setOpen] = React.useState(false);
	const [copied, setCopied] = useState(false);
	const [copiedIframe, setCopiedIframe] = useState(false);
	const [spinUrl, setSpinUrl] = useState(`https://octospin.com/share/`);

	const [iframeWidth, setIframeWidth] = useState(600)
	const [iframeHeight, setIframeHeight] = useState(400)

	const [iframteText, setIframeText] = useState(``);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setSpinUrl(`${window.location.origin}/share/${spinId}`);
		var text = `<iframe src="${`${window.location.origin}/share/${spinId}?fullscreen=true`}" style="border:none;" width="${iframeWidth}" height="${iframeHeight}"></iframe>`;
		setIframeText(text);
	}, [spinId, iframeWidth, iframeHeight]);

	// useEffect(() => {
	// 	if (copied == true) {
	// 		const delayCopyReset = setTimeout(() => {
	// 			setCopied(false);
	// 			clearTimeout(delayCopyReset);
	// 		}, 3000);
	// 	}
	// 	return;
	// }, [copied]);

	const handleUrlCopy = useCallback(() => {
		try {
			navigator.clipboard.writeText(spinUrl);
			setCopied(true);
			const delayCopyReset = setTimeout(() => {
				setCopied(false);
				clearTimeout(delayCopyReset);
			}, 3000);
			return;
		} catch {
			return;
		}
	}, [spinUrl]);
	const handleIframeCopy = useCallback(() => {
		try {
			navigator.clipboard.writeText(iframteText);
			setCopiedIframe(true);
			const delayCopyReset = setTimeout(() => {
				setCopiedIframe(false);
				clearTimeout(delayCopyReset);
			}, 3000);
			return;
		} catch {
			return;
		}
	}, [iframteText]);

	return (
		<>
			<CustomButton variant="outline" icon={<Share size={14} />} text={'Share'} onClick={handleClickOpen} />
			<Dialog
				fullWidth={true}
				maxWidth={'sm'}
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: {
						width: '100%',
						maxWidth: '500px',
						margin: '0px',
						borderRadius: '20px',
					},
				}}
			>
				<DialogTitle>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							alignItems: 'center',
						}}
					>
						<Button onClick={handleClose}>Close</Button>
					</div>
				</DialogTitle>
				<DialogContent
					sx={{
						padding: '20px',
					}}
				>
					<div>
						<span
							style={{
								fontSize: '1.2em',
								fontWeight: 'bold',
								display: 'block',
							}}
						>
							Link to spin
						</span>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '16px',
								padding: '16px',
								alignItems: 'center',
							}}
						>
							<a target="_blank" href={spinUrl}>
								{spinUrl.replace('https://', '')}
							</a>

							<CustomButton
								icon={copied ? <Check size={14} /> : <Copy size={14} />}
								text={copied ? 'Copied' : 'Copy link'}
								onClick={handleUrlCopy}
								variant={copied ? 'subtle' : 'filled'}
							/>
						</div>

						<span
							style={{
								fontSize: '1.2em',
								fontWeight: 'bold',
								display: 'block',
							}}
						>
							Embed as iFrame
						</span>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '16px',
								padding: '16px',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
									gap: '16px',
								}}
							>
								<TextField
									InputProps={{
										endAdornment: (
											<InputAdornment sx={{ color: 'rgba(150, 150, 150, 0.5)' }} position="end">
												px
											</InputAdornment>
										),
									}}
									label="Width"
									variant="outlined"
									size="small"
									sx={{
										width: '6em',
									}}
									value={iframeWidth}
									onChange={(e) => {setIframeWidth(e.target.value)}}
									/>
								<TextField
									InputProps={{
										endAdornment: (
											<InputAdornment sx={{ color: 'rgba(150, 150, 150, 0.5)' }} position="end">
												px
											</InputAdornment>
										),
									}}
									label="Height"
									variant="outlined"
									size="small"
									sx={{
										width: '6em',
									}}
									value={iframeHeight}
									onChange={(e) => {setIframeHeight(e.target.value)}}
									/>
									
							</div>

							<div style={{ float: 'right' }}>
								<CustomButton
									icon={copiedIframe ? <Check size={14} /> : <Copy size={14} />}
									text={copiedIframe ? 'Copied' : 'Copy iFrame text'}
									onClick={handleIframeCopy}
									variant={copiedIframe ? 'subtle' : 'filled'}
								/>
							</div>

							{iframteText}
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default ShareDialogButton;
