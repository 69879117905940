import React, { useState, useEffect, useCallback, useContext, useRef, useLayoutEffect } from 'react';
import SpinContext from '../../contexts/SpinContext';
import AppContext from '../../contexts/AppContext';
import SettingsMenu from './SettingsMenu';
import { ZoomIn, ZoomOut, ArrowForwardIos, ArrowBackIos, PlayArrow, Pause, ControlCamera, Fullscreen } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import CorrectNumber from '../../functions/CorrectNumber';
import CorrectNumberBounce from '../../functions/CorrectNumberBounce';
import ArrayOrderSort from '../../functions/ArrayOrderSort';
import LoadingSpinnerCenter from '../LoadingSpinnerCenter';
import { useHistory, useLocation } from 'react-router-dom';
import { decode } from 'html-entities';
import LogoAnimated from '../../assets/LogoAnimated';
import ZoomView from './ZoomView';
import { isNullOrUndefined } from '../../functions/misc';
import { useSelector, useDispatch } from 'react-redux';
import {
	imagePositionValue,
	changeImagePositionTo,
	changeMouseIsDown,
	playIncrementPosition,
	promptVisibleValue,
	promptRefreshValue,
	changePromptVisible,
	setInitialLoadIndex,
	setinitialSpinData,
	playSpinInteract,
	pauseSpinInteract,
	spinPlayingValue,
} from '../../store/interactionSlice';
import AnimatedPrompt from './AnimatedPrompt';
import Information from '../dialogs/Information';
import { resetSpinData, setSpinData, spinDataGetter } from '../../store/interactionSlice';
import { backendUrlValue } from '../../store/appInfoSlice';
import { downloadImages, downloadLowResImages, loadRemainingImages } from '../../functions/view/downloadImages';
import { CustomIconButton } from '../buttons/CustomButton';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Interact = (props) => {
	const _SpinContext = useContext(SpinContext);
	const _AppContext = useContext(AppContext);
	const { mode, isFullPage, displayWidth, displayHeight } = props;
	let query = useQuery();

	const imagePosition = useSelector(imagePositionValue);
	const dispatch = useDispatch();
	const promptVisible = useSelector(promptVisibleValue);
	const promptRefresh = useSelector(promptRefreshValue);
	const _bounceHalfCircle = useSelector(spinDataGetter('bounceHalfCircle'));
	const _id = useSelector(spinDataGetter('id'));
	const _motionOnLoad = useSelector(spinDataGetter('motionOnLoad'));

	const _showOctospinBranding = useSelector(spinDataGetter('showOctospinBranding'));
	const _showPlaybackControls = useSelector(spinDataGetter('showPlaybackControls'));
	const _showPlayPauseButton = useSelector(spinDataGetter('showPlayPauseButton'));
	// const _showFullscreen = useSelector(spinDataGetter('showFullscreen'));
	const _dragDirectionNegative = useSelector(spinDataGetter('dragDirectionNegative'));
	const _enableZoom = useSelector(spinDataGetter('enableZoom'));
	const _showZoomButton = useSelector(spinDataGetter('showZoomButton'));
	const _playSpeedRangeValue = useSelector(spinDataGetter('playSpeedRangeValue'));
	const _dragFactorRangeValue = useSelector(spinDataGetter('dragFactorRangeValue'));
	const _firstImageLoadLindex = useSelector(spinDataGetter('firstImageLoadLindex'));
	const _showZoomPad = useSelector(spinDataGetter('showZoomPad'));
	const _images = useSelector(spinDataGetter('images'));
	const _settingsMenuTextColor = useSelector(spinDataGetter('settingsMenuTextColor'));
	const _basicButtonBackgroundColor = useSelector(spinDataGetter('basicButtonBackgroundColor'));
	const _animatedPromptColor = useSelector(spinDataGetter('animatedPromptColor'));
	const _settingsMenuBackgroundColor = useSelector(spinDataGetter('settingsMenuBackgroundColor'));
	const _pageBackgroundColor = useSelector(spinDataGetter('pageBackgroundColor'));
	const _textPrompt = useSelector(spinDataGetter('textPrompt'));
	const _animatedPrompt = useSelector(spinDataGetter('animatedPrompt'));
	const _animatedPromptDropshadow = useSelector(spinDataGetter('animatedPromptDropshadow'));
	const _promptToSpin = useSelector(spinDataGetter('promptToSpin'));
	const _promptTextColor = useSelector(spinDataGetter('promptTextColor'));
	const _promptBackgroundColor = useSelector(spinDataGetter('promptBackgroundColor'));
	const _promptOpacity = useSelector(spinDataGetter('promptOpacity'));
	const _promptShowDropshadow = useSelector(spinDataGetter('promptShowDropshadow'));
	const _showSettingsMenu = useSelector(spinDataGetter('showSettingsMenu'));
	const _spinPlaying = useSelector(spinPlayingValue);
	const [compositeLoadError, setCompositeLoadError] = useState(false);
	const BACKEND_URL = useSelector(backendUrlValue);
	const history = useHistory();
	const dimLayerRef = useRef(null);
	const [loadImagePostionSet, setLoadImagePostionSet] = useState(false);
	const pauseButtonEl = useRef(null);
	const [downloadedImages, setDownloadedImages] = useState([]);
	const [lowResImages, setLowResImages] = useState([]);
	const [initDownloadedIds, setInitDownloadedIds] = useState([]);
	const [initialInteractionOccured, setInitialInteractionOccured] = useState(false);
	const [fullscreen, setFullscreen] = useState(false);

	useEffect(() => {
		try {
			if (window.location.pathname.startsWith('/v/') || window.location.pathname.startsWith('/share/')) {
				const fs = query.get('fullscreen') == 'true';
				if (fs) {
					setFullscreen(true);
				}

				var parts = window.location.pathname.split('/');
				var id = parts.pop() || parts.pop(); //handle trailing slashes
				window.history.replaceState(null, '', `/share/${id}`);
			}
		} catch {}
	}, []);

	const concatDownloadedCB = useCallback(
		(images) => {
			setDownloadedImages((curr) => {
				curr = curr.concat(images);
				return curr;
			});
		},
		[setDownloadedImages]
	);

	const triggerFullLoad = useCallback(
		async (id) => {
			var w = 400;
			var h = 400;
			try {
				if (displayWidth > 0 || displayHeight > 0) {
					w = displayWidth;
					h = displayHeight;
				} else {
					w = window.innerWidth;
					h = window.innerHeight;
				}
			} catch {}
			await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/Spin/FullLoad?spinId=${id}&w=${w}&h=${h}`, {
				method: 'GET',
			});
		},
		[displayWidth, displayHeight]
	);

	const interactionCB = useCallback(async () => {
		if (initialInteractionOccured) {
			return;
		}
		setInitialInteractionOccured(true);

		// load remaining if they didnt load on page load
		if (!_motionOnLoad) {
			const images = [..._images];
			await loadRemainingImages(initDownloadedIds, images, concatDownloadedCB, 5);

			triggerFullLoad(_id);
		}
	}, [initialInteractionOccured, downloadedImages, _images, _id, _motionOnLoad, _firstImageLoadLindex, initDownloadedIds, concatDownloadedCB]);

	const controlOverflowOn = useCallback(() => {
		document.querySelector('body').style.overflow = 'hidden';
		document.querySelector('body').style.touchAction = 'pan-y';
		document.querySelector('html').style.overflow = 'hidden';
		document.querySelector('html').style.touchAction = 'pan-y';
		document.querySelector('#viewPortMeta').setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
	}, []);

	const controlOverflowOff = useCallback(() => {
		document.querySelector('body').style.overflow = 'auto';
		document.querySelector('body').style.touchAction = '';
		document.querySelector('html').style.overflow = 'auto';
		document.querySelector('html').style.touchAction = '';
		document.querySelector('#viewPortMeta').setAttribute('content', 'width=device-width, initial-scale=1');
	}, []);

	useEffect(() => {
		controlOverflowOn();
		return () => {
			controlOverflowOff();
		};
	}, []);

	useEffect(() => {
		if (isNullOrUndefined(_pageBackgroundColor) || !isFullPage) {
			return;
		}
		document.querySelector('body').style.backgroundColor = _pageBackgroundColor;
		document.querySelector('html').style.backgroundColor = _pageBackgroundColor;
	}, [_pageBackgroundColor, isFullPage]);

	const pickCorrectNumber = useCallback(
		(num, max) => {
			if (_bounceHalfCircle) {
				return CorrectNumberBounce(num, max);
			} else {
				return CorrectNumber(num, max);
			}
		},
		[_bounceHalfCircle]
	);

	const initCustomBits = useCallback(() => {
		if (isNullOrUndefined(_images)) {
			return;
		}
		if (loadImagePostionSet === false) {
			setLoadImagePostionSet(true);
			let positionToSet = pickCorrectNumber(_firstImageLoadLindex >= 0 ? _firstImageLoadLindex + 0.5 : 0.5, _images.length);
			dispatch(setInitialLoadIndex(positionToSet));
			dispatch(changeImagePositionTo(positionToSet));
			return;
		} else {
			return;
		}
	}, [_images, loadImagePostionSet]);

	const nickname = useSelector(spinDataGetter('nickname'));

	const setTitle = useCallback(() => {
		if (mode === 'edit') {
			document.title = `Octospin | Edit | ${nickname ? nickname : ''}`;
		} else if (isFullPage) {
			document.title = `Octospin${nickname ? `| ${nickname}` : ''}`;
		}
	}, [isFullPage, mode, nickname]);

	useEffect(() => {
		setTitle();
		return () => {
			document.title = `Octospin`;
		};
	}, [mode, isFullPage, nickname]);

	const setImages = useCallback(() => {
		if (isNullOrUndefined(_images)) {
			return;
		}
		initCustomBits();
	}, [props.mode, _images, BACKEND_URL, _SpinContext.changeImageUriArray, ArrayOrderSort]);

	useEffect(() => {
		setImages();
	}, [_images]);

	const autoMotionOnLoad = useCallback(
		async (images, id) => {
			await loadRemainingImages(initDownloadedIds, images, concatDownloadedCB, 10);
			dispatch(playSpinInteract());
			triggerFullLoad(id);
		},
		[dispatch, playSpinInteract, triggerFullLoad, initDownloadedIds, concatDownloadedCB]
	);

	const getSpin = useCallback(
		async (token) => {
			return new Promise(async (resolve, reject) => {
				var w = 400;
				var h = 400;
				try {
					if (displayWidth > 0 || displayHeight > 0) {
						w = displayWidth;
						h = displayHeight;
					} else {
						w = window.innerWidth;
						h = window.innerHeight;
					}
				} catch {}
				const response = await fetch(`${BACKEND_URL}/api/Spin/${props.id}?w=${w}&h=${h}`, {
					method: 'GET',
					headers: {
						Accept: 'text/plain',
						Authorization: token ? `Bearer ${token}` : null,
					},
				});

				if (response.status !== 200) {
					reject(`spin ${props.id} not found. Code 400005`);
				}
				const data = await response.json();
				data.images.sort((a, b) => a.order - b.order);
				setLowResImages(await downloadLowResImages(data.images, data.firstImageLoadLindex));

				setDownloadedImages(await downloadImages(data.images, data.firstImageLoadLindex, setInitDownloadedIds));

				dispatch(setSpinData(data));
				// initCustomBits();

				if (props.mode === 'edit') {
					dispatch(setinitialSpinData(data));
				}
				resolve(data);
			});
		},
		[props.mode, BACKEND_URL, props.id, initCustomBits, displayWidth, displayHeight]
	);

	const croppedCB = useCallback(
		(imageArray) => {
			setDownloadedImages((current) => {
				for (var i = 0; i < imageArray.length; i++) {
					var imageIndex = current.findIndex((x) => x.id == imageArray[i].id);
					if (imageIndex > -1) {
						current[imageIndex] = imageArray[i];
					} else {
						current.push(imageArray[i]);
					}
				}
				return current;
			});
		},
		[downloadedImages, setDownloadedImages]
	);

	const [zoomViewKey, setZoomViewKey] = useState(0);

	const updateCroppedDisplayImages = useCallback(async () => {
		if (isNullOrUndefined(_images) || isNullOrUndefined(downloadedImages)) {
			console.error('_images or downloadedImages undefined');
			return;
		}
		const cropped = _images.filter((x) => x.freshlyCropped || x.addedImage);
		if (cropped.length == 0) {
			return;
		}
		_SpinContext.setPageMightBeDirty(true);

		await loadRemainingImages([], cropped, croppedCB, 10);
		setZoomViewKey((prev) => prev + 1);
	}, [_images, downloadedImages, croppedCB, setZoomViewKey, _SpinContext]);

	useEffect(() => {
		if (mode === 'edit') {
			updateCroppedDisplayImages();
		}
	}, [_images, mode]);

	const getTokenThenSpin = useCallback(async () => {
		var accessToken = null;

		if (props.mode == 'edit' || props.mode == 'myspins') {
			try {
				accessToken = await _AppContext.getAccessTokenSilently({
					audience: process.env.REACT_APP_AUTH0_AUDIENCE,
					// audience: `https://prod-backend.prosp.in/`,
				});
			} catch {
				accessToken = null;
			}
		}

		var data;
		try {
			data = await getSpin(accessToken);

			// if motionOnLoad is enabled
			try {
				if (data.motionOnLoad) {
					autoMotionOnLoad(data.images, data.id);
				}
			} catch {}
		} catch (error) {
			if (error.message.toLowerCase() === 'failed to fetch') {
				history.push(`/error?message=There was an issue connecting to Octospin's infrastructure. Code 500000`);
			} else if (error.message.toLowerCase() === 'cancelled') {
				return;
			} else {
				history.push(`/error?message=${error}`);
			}
		}
	}, [props.id, props.mode, autoMotionOnLoad]);

	useEffect(async () => {
		getTokenThenSpin();
	}, [props.id]);

	const playSpin = useCallback(
		(speedChange) => {
			if (_SpinContext.showZoom) {
				_SpinContext.changeShowZoom(false);
				_SpinContext.setZoomOutAction(true);
			}

			interactionCB();

			dispatch(playSpinInteract());

			return () => {
				dispatch(pauseSpinInteract());
			};
		},
		[_SpinContext.changeShowZoom, _SpinContext.setZoomOutAction, interactionCB, _SpinContext.showZoom, playIncrementPosition, dispatch]
	);

	const resetSpinPlayWithSpeed = useCallback(() => {
		if (_spinPlaying) {
			playSpin(true);
		}
	}, [_spinPlaying, playSpin]);

	useEffect(() => {
		resetSpinPlayWithSpeed();
	}, [_playSpeedRangeValue, _dragFactorRangeValue]);

	const resetVisiblePrompt = useCallback(() => {
		dispatch(changePromptVisible(true));
	}, [dispatch, changePromptVisible]);

	useEffect(() => {
		resetVisiblePrompt();
		return () => {
			resetVisiblePrompt();
		};
	}, []);

	const spinPadMouseDown = useCallback(
		(e) => {
			dispatch(changeMouseIsDown({ down: true, clientX: e.clientX }));
			dispatch(changePromptVisible(false));
			dispatch(pauseSpinInteract());
		},
		[_SpinContext.showZoom, changeMouseIsDown]
	);

	const spinPadTouchStart = useCallback(
		(e) => {
			dispatch(changeMouseIsDown({ down: true, clientX: e.touches[0].clientX }));
			dispatch(changePromptVisible(false));
			dispatch(pauseSpinInteract());
		},
		[_SpinContext, changeMouseIsDown, dispatch]
	);

	useEffect(() => {
		return () => {
			dispatch(pauseSpinInteract());
		};
	}, [dispatch, pauseSpinInteract]);

	useEffect(() => {
		return () => {
			dispatch(resetSpinData());
			setCompositeLoadError(false);
		};
	}, [resetSpinData, dispatch]);

	const handleLeftRight = useCallback(
		(e) => {
			try {
				if (!(e.key == 'ArrowLeft' || e.key == 'ArrowRight')) {
					return;
				}
				if (e.target.classList.contains('sliderSettingsWrapper')) {
					return;
				}
				var diff = 0;

				if (e.key == 'ArrowLeft') {
					diff = _dragDirectionNegative ? 1 : -1;
				} else if (e.key == 'ArrowRight') {
					diff = _dragDirectionNegative ? -1 : 1;
				}
				dispatch(changePromptVisible(false));
				dispatch(pauseSpinInteract());
				interactionCB();
				dispatch(changeImagePositionTo(pickCorrectNumber(imagePosition + diff, _images.length)));
			} catch {}
		},
		[changeImagePositionTo, changePromptVisible, pauseSpinInteract, pickCorrectNumber, interactionCB, imagePosition, _images, _dragDirectionNegative]
	);
	useEffect(() => {
		window.addEventListener('keydown', handleLeftRight);

		return () => {
			window.removeEventListener('keydown', handleLeftRight);
		};
	}, [handleLeftRight]);

	return (
		<div
			style={{
				position: 'relative',
				height: '100%',
				width: '100%',
			}}
		>
			<div
				style={{
					position: 'absolute',
					backgroundColor: 'rgba(255, 255, 255, 1)',
					height: '100%',
					width: '100%',
				}}
			></div>

			<div
				ref={dimLayerRef}
				className="viewerOverarch"
				style={{
					backgroundColor: _pageBackgroundColor ? _pageBackgroundColor : '#fff',
				}}
			>
				{(_animatedPrompt || _textPrompt) && promptVisible && (
					<div key={promptRefresh} className="promptWrapper">
						{_animatedPrompt && <AnimatedPrompt animatedPromptDropshadow={_animatedPromptDropshadow} animatedPromptColor={_animatedPromptColor} />}
						{_textPrompt && (
							<div
								style={{
									backgroundColor: _promptBackgroundColor,
									boxShadow: _promptShowDropshadow ? '0 4px 8px rgba(0, 0, 0, 0.16), 0 4px 8px rgba(0,0,0,0.23)' : 'none',
									color: _promptTextColor,
									opacity: _promptOpacity / 100,
									padding: '12px 18px',
									borderRadius: '100px',
									fontWeight: 'bold',
								}}
							>
								{decode(_promptToSpin)}
							</div>
						)}
					</div>
				)}

				{_SpinContext.loading === true && <LoadingSpinnerCenter />}
				<ZoomView
					refreshKey={zoomViewKey}
					mode={props.mode}
					interactionCB={interactionCB}
					downloadedImages={downloadedImages}
					lowResImages={lowResImages}
					compositeLoadError={compositeLoadError}
					backendUrl={BACKEND_URL}
				/>
				<div className="kbAssist">
					<button
						tabIndex="0"
						type="button"
						title="Previous Image"
						onClick={() => {
							dispatch(changePromptVisible(false));
							dispatch(pauseSpinInteract());
							interactionCB();
							const diff = _dragDirectionNegative ? 1 : -1;
							dispatch(changeImagePositionTo(pickCorrectNumber(imagePosition + diff, _images.length)));
						}}
					>
						Previous
					</button>
					<button
						tabIndex="0"
						type="button"
						title="Next Image"
						onClick={() => {
							dispatch(changePromptVisible(false));
							dispatch(pauseSpinInteract());
							interactionCB();
							const diff = _dragDirectionNegative ? -1 : 1;
							dispatch(changeImagePositionTo(pickCorrectNumber(imagePosition + diff, _images.length)));
						}}
					>
						Next
					</button>
				</div>

				{_showOctospinBranding && (
					<div className="brandingWrapper">
						<a href="/" target="_blank" rel="noreferrer" title="octospin.com">
							<LogoAnimated dimensions={{ height: '25.6px', width: '32px' }} />
						</a>
					</div>
				)}
				<div
					className="settingsWrapper"
					style={{
						width: _SpinContext.showZoom === true ? '100%' : 'fit-content',
					}}
				>
					{_SpinContext.showZoom === true && _showZoomPad && (
						<div
							className="spinPad"
							style={{
								background: _settingsMenuBackgroundColor,
								color: _settingsMenuTextColor,
							}}
							draggable={false}
							onMouseDown={spinPadMouseDown}
							onTouchStart={spinPadTouchStart}
						>
							<ControlCamera sx={{ color: _settingsMenuTextColor + '!important' }} color={_settingsMenuTextColor} />
							<div
								style={{
									position: 'absolute',
									right: '8px',
								}}
							>
								<Information iconColor={_settingsMenuTextColor} value="Press and drag on this element to spin." />
							</div>
						</div>
					)}

					<div className="settingsWrapperActions">
						{_SpinContext.showZoom === true && (
							<CustomIconButton
								id="zoom-out-button"
								variant="hover"
								icon={<ZoomOut />}
								color={_basicButtonBackgroundColor}
								onClick={() => {
									_SpinContext.changeShowZoom(false);
									_SpinContext.setZoomOutAction(true);
								}}
							/>
						)}
						{_showZoomButton && _enableZoom && (
							<CustomIconButton
								id="zoom-in-button"
								variant="hover"
								icon={<ZoomIn />}
								color={_basicButtonBackgroundColor}
								onClick={() => {
									if (_spinPlaying) {
										dispatch(pauseSpinInteract());
									}
									_SpinContext.setZoomInAction(true);
									dispatch(changePromptVisible(false));
								}}
							/>
						)}
						<IconButton
							id="pause-button"
							ref={pauseButtonEl}
							style={{ display: 'none' }}
							onClick={() => {
								if (_spinPlaying) {
									dispatch(pauseSpinInteract());
								}
							}}
						>
							<Pause />
						</IconButton>

						{_showPlayPauseButton && (
							<CustomIconButton
								id="play-button"
								variant="hover"
								icon={_spinPlaying ? <Pause /> : <PlayArrow />}
								color={_basicButtonBackgroundColor}
								onClick={() => {
									dispatch(changePromptVisible(false));
									if (_spinPlaying) {
										dispatch(pauseSpinInteract());
									} else {
										playSpin();
									}
								}}
							/>
						)}
						{/* {_showFullscreen && !fullscreen && ( */}
						{!_SpinContext.loading && fullscreen && (
							<CustomIconButton
								id="fullscreen-button"
								variant="hover"
								icon={<Fullscreen />}
								color={_basicButtonBackgroundColor}
								onClick={() => {
									window.open(`/share/${_id}`, '_blank');
								}}
							/>
						)}

						{_showPlaybackControls === true && (
							<>
								<CustomIconButton
									id="back-button"
									variant="hover"
									icon={<ArrowBackIos />}
									color={_basicButtonBackgroundColor}
									onClick={() => {
										dispatch(changePromptVisible(false));
										dispatch(pauseSpinInteract());
										const diff = _dragDirectionNegative ? 1 : -1;
										dispatch(changeImagePositionTo(pickCorrectNumber(imagePosition + diff, _images.length)));
									}}
								/>

								<CustomIconButton
									id="forward-button"
									variant="hover"
									icon={<ArrowForwardIos />}
									color={_basicButtonBackgroundColor}
									onClick={() => {
										dispatch(changePromptVisible(false));
										dispatch(pauseSpinInteract());
										const diff = _dragDirectionNegative ? -1 : 1;
										dispatch(changeImagePositionTo(pickCorrectNumber(imagePosition + diff, _images.length)));
									}}
								/>
							</>
						)}
						{_showSettingsMenu && <SettingsMenu mode={props.mode} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Interact;
