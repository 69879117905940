import { delaySomething, isNullOrUndefined } from "./misc"

const getUser = (authId, backendBase, token) => {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(`${backendBase}/api/User`, {
            headers: {
                Accept: 'text/plain',
                Authorization: `Bearer ${token}`,
            },
        }).catch(error => {
            reject(error)
        })
        if (isNullOrUndefined(response)) {
            reject()
        }
        if (response.status === 200) {
            resolve(await response.json())
        } else {
            reject()
        }
    })
}

const tryGetUser = (authId, backendBase, token) => {
    return new Promise(async (resolve, reject) => {
        var tries = 0
        var gotten = false
        var error = ''
        while (tries < 5 && !gotten ) {
            try {
                var response = await getUser(authId, backendBase, token)
                gotten = true
                resolve(response)
            } catch (err) {
                tries += 1
                error = err
            }
            await delaySomething(500 * tries)
        }
        if (tries >= 5 && !gotten) {
            reject(error)
        }
    })
}

export {getUser, tryGetUser}